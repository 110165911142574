import {
    GET_PROFESSOR_TO_SUBJECTS_START,
    GET_PROFESSOR_TO_SUBJECTS_SUCCESS,
    GET_PROFESSOR_TO_SUBJECTS_FAIL,
    REGISTER_PROFESSOR_TO_SUBJECTS_START,
    REGISTER_PROFESSOR_TO_SUBJECTS_SUCCESS,
    REGISTER_PROFESSOR_TO_SUBJECTS_FAIL,
    SHOW_PROFESSOR_INFO_MODAL,
} from '../actions';


import { updateObject } from '../utility';

const initialState = {
    professorToSubjects: [],
    subjects: [],

    showProfessorInfoModal: false,
    isLoadingOnRegistering: false,

    loading: false,
    message: null,
    messageType: null,
}

const getProfessorToSubjectsStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        message: null,
        messageType: null,
    });
};

const getProfessorToSubjectsSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        professorToSubjects: action.professorToSubjects,
        subjects: action.subjects,
    });
};

const getProfessorToSubjectsFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        message: action.error,
        messageType: 'error',
    });
};

const registerProfessorToSubjectsStart = (state, action) => {
    return updateObject(state, {
        isLoadingOnRegistering: true,
        message: null,
        messageType: null,
    });
};

const registerProfessorToSubjectsSuccess = (state, action) => {
    return updateObject(state, {
        isLoadingOnRegistering: false,
        professorToSubjects: action.professorToSubjects,
        subjects: action.subjects,
        message: 'Informazioni docente aggiornate con successo',
        messageType: 'success',
        showProfessorInfoModal: false,
    });
};

const registerProfessorToSubjectsFail = (state, action) => {
    return updateObject(state, {
        isLoadingOnRegistering: false,

        message: action.errorMessage,
        messageType: 'error',
    });
};

/////////////////////////////////////////////////////////////////////////////
// OTHER
/////////////////////////////////////////////////////////////////////////////
const setShowProfessorInfoModal = (state, action) => {
    return updateObject(state, {
        showProfessorInfoModal: action.show,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PROFESSOR_TO_SUBJECTS_START: return getProfessorToSubjectsStart(state, action);
        case GET_PROFESSOR_TO_SUBJECTS_SUCCESS: return getProfessorToSubjectsSuccess(state, action);
        case GET_PROFESSOR_TO_SUBJECTS_FAIL: return getProfessorToSubjectsFail(state, action);
        case REGISTER_PROFESSOR_TO_SUBJECTS_START: return registerProfessorToSubjectsStart(state, action);
        case REGISTER_PROFESSOR_TO_SUBJECTS_SUCCESS: return registerProfessorToSubjectsSuccess(state, action);
        case REGISTER_PROFESSOR_TO_SUBJECTS_FAIL: return registerProfessorToSubjectsFail(state, action);
        case SHOW_PROFESSOR_INFO_MODAL: return setShowProfessorInfoModal(state, action);
        default: return state;
    }
}

export default reducer;