import {
    GET_BOARD_MEETING_START,
    GET_BOARD_MEETING_SUCCESS,
    GET_BOARD_MEETING_FAIL,
    GET_BOARD_MEETING_PRINT_START,
    GET_BOARD_MEETING_PRINT_SUCCESS,
    GET_BOARD_MEETING_PRINT_FAIL,
    GET_SUBJECTS_START,
    GET_SUBJECTS_SUCCESS,
    GET_SUBJECTS_FAIL,
    GET_SCHOOL_CLASSES_START,
    GET_SCHOOL_CLASSES_SUCCESS,
    GET_SCHOOL_CLASSES_FAIL,
} from '../actions';


import { updateObject } from '../utility';

const initialState = {
    boardMeeting: [],
    subjects: [],
    schoolClasses: [],

    isLoadingOnPrinting: false,
    isLoadingBoardMeeting: false,
    isLoadingSubjects: false,
    isLoadingSchoolClasses: false,

    message: null,
    messageType: null,
}

const getBoardMeetingStart = (state, action) => {
    return updateObject(state, {
        isLoadingBoardMeeting: true,
        message: null,
        messageType: null,
    });
};

const getBoardMeetingSuccess = (state, action) => {
    return updateObject(state, {
        isLoadingBoardMeeting: false,
        boardMeeting: action.boardMeeting,
    });
};

const getBoardMeetingFail = (state, action) => {
    return updateObject(state, {
        isLoadingBoardMeeting: false,
        message: action.error,
        messageType: 'error',
    });
};

const getBoardMeetingPrintStart = (state, action) => {
    return updateObject(state, {
        isLoadingOnPrinting: true,
        message: null,
        messageType: null,
    });
};

const getBoardMeetingPrintSuccess = (state, action) => {
    return updateObject(state, {
        isLoadingOnPrinting: false,
    });
};

const getBoardMeetingPrintFail = (state, action) => {
    return updateObject(state, {
        isLoadingOnPrinting: false,
        message: action.errorMessage,
        messageType: 'error',
    });
};

const getSubjectsStart = (state, action) => {
    return updateObject(state, {
        isLoadingSubjects: true,
        message: null,
        messageType: null,
    });
};

const getSubjectsSuccess = (state, action) => {
    return updateObject(state, {
        isLoadingSubjects: false,
        subjects: action.subjects,
    });
};

const getSubjectsFail = (state, action) => {
    return updateObject(state, {
        isLoadingSubjects: false,
        message: action.error,
        messageType: 'error',
    });
};

const getSchoolClassesStart = (state, action) => {
    return updateObject(state, {
        isLoadingSchoolClasses: true,
        message: null,
        messageType: null,
    });
};

const getSchoolClassesSuccess = (state, action) => {
    return updateObject(state, {
        isLoadingSchoolClasses: false,
        schoolClasses: action.schoolClasses,
    });
};

const getSchoolClassesFail = (state, action) => {
    return updateObject(state, {
        isLoadingSchoolClasses: false,
        message: action.error,
        messageType: 'error',
    });
};
/////////////////////////////////////////////////////////////////////////////
// OTHER
/////////////////////////////////////////////////////////////////////////////

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_BOARD_MEETING_START: return getBoardMeetingStart(state, action);
        case GET_BOARD_MEETING_SUCCESS: return getBoardMeetingSuccess(state, action);
        case GET_BOARD_MEETING_FAIL: return getBoardMeetingFail(state, action);
        case GET_BOARD_MEETING_PRINT_START: return getBoardMeetingPrintStart(state, action);
        case GET_BOARD_MEETING_PRINT_SUCCESS: return getBoardMeetingPrintSuccess(state, action);
        case GET_BOARD_MEETING_PRINT_FAIL: return getBoardMeetingPrintFail(state, action);
        case GET_SUBJECTS_START: return getSubjectsStart(state, action);
        case GET_SUBJECTS_SUCCESS: return getSubjectsSuccess(state, action);
        case GET_SUBJECTS_FAIL: return getSubjectsFail(state, action);
        case GET_SCHOOL_CLASSES_START: return getSchoolClassesStart(state, action);
        case GET_SCHOOL_CLASSES_SUCCESS: return getSchoolClassesSuccess(state, action);
        case GET_SCHOOL_CLASSES_FAIL: return getSchoolClassesFail(state, action);
        default: return state;
    }
}

export default reducer;