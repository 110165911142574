import {
    COMPUTE_REPLACEMENTS_START,
    COMPUTE_REPLACEMENTS_SUCCESS,
    COMPUTE_REPLACEMENTS_FAIL,
    SET_SHOW_REPLACEMENT_CHECKOUT_MODAL,
} from '../actions';

import { updateObject } from '../utility';

const initialState = {
    classesLeaveEntry: [],

    showReplacementCheckoutModal: false,
    loading: false,

    needsForce: false,

    message: null,
    messageType: null,
};

const setShowReplacementCheckoutModal = (state, action) => {
    return updateObject(state, { showReplacementCheckoutModal: action.show, needsForce: false });
};

const computeReplacementsStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        needsForce: false,
        message: null,
        messageType: null,
    });
};

const computeReplacementsSuccess = (state, action) => {
    return updateObject(state, { loading: false, showReplacementCheckoutModal: false, needsForce: false });
};

const computeReplacementsFail = (state, action) => {
    let showReplacementCheckoutModal = false;
    if (action.needsForce) {
        showReplacementCheckoutModal = true;
    }

    return updateObject(state, {
        loading: false,
        showReplacementCheckoutModal: showReplacementCheckoutModal,
        needsForce: action.needsForce,
        message: action.errorMessage,
        messageType: 'error',
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case COMPUTE_REPLACEMENTS_START: return computeReplacementsStart(state, action);
        case COMPUTE_REPLACEMENTS_SUCCESS: return computeReplacementsSuccess(state, action);
        case COMPUTE_REPLACEMENTS_FAIL: return computeReplacementsFail(state, action);
        case SET_SHOW_REPLACEMENT_CHECKOUT_MODAL: return setShowReplacementCheckoutModal(state, action);
        default: return state;
    }
};

export default reducer;