import {
    GET_ANY_HOUR_PROFESSORS_AVAILABLE_START,
    GET_ANY_HOUR_PROFESSORS_AVAILABLE_SUCCESS,
    GET_ANY_HOUR_PROFESSORS_AVAILABLE_FAIL,
    GET_PROFESSOR_FREE_HOURS_START,
    GET_PROFESSOR_FREE_HOURS_SUCCESS,
    GET_PROFESSOR_FREE_HOURS_FAIL,
    SAVE_ABSENT_PROFESSOR_AND_PERM_START,
    SAVE_ABSENT_PROFESSOR_AND_PERM_SUCCESS,
    SAVE_ABSENT_PROFESSOR_AND_PERM_FAIL,
    SET_PROFESSOR_SCHEDULE,
    SET_SHOW_PERM_PROFESSOR_MODAL,
} from '../actions';

import { updateObject } from '../utility';

const initialState = {
    absenceDayAvailability: [],
    hourlyAvailableProfessors: [],
    professorSchedule: [],
    professorAvailability: [],
    schoolClassSettings: null,

    showPermProfessorModal: false,
    loading: false,
    loadingHourlyAvailableProfessors: false,
    isProfessorAvailabilityLoading: false,

    message: null,
    messageType: null,
};

/////////////////////////////////////////////////////////////////////////////
// PROFESSOR AVAILABILITY
/////////////////////////////////////////////////////////////////////////////
const getAnyHourProfessorsAvailableStart = (state, action) => {
    return updateObject(state, {
        absenceDayAvailability: [],
        hourlyAvailableProfessors: [],
        professorSchedule: [],
        professorAvailability: [],
        loadingHourlyAvailableProfessors: true,

        message: null,
        messageType: null,
    });
};

const getAnyHourProfessorsAvailableSuccess = (state, action) => {
    const availability = action.availability.reduce(function (map, obj) {
        map[obj.professor.id] = obj.presence_hours;
        return map;
    }, {});

    const professors = action.availability
        .filter(availability => availability.presence_hours.length > 0)
        .map(availability => availability.professor);

    return updateObject(state, {
        absenceDayAvailability: availability,
        hourlyAvailableProfessors: [...professors],
        loadingHourlyAvailableProfessors: false,
        schoolClassSettings: action.schoolClassSettings,
    });
};

const getAnyHourProfessorsAvailableFail = (state, action) => {
    return updateObject(state, {
        loadingHourlyAvailableProfessors: false,
        // message: action.errorMessage,
        // messageType: 'error'
    });
};

/////////////////////////////////////////////////////////////////////////////
// PROFESSOR SCHEDULE
/////////////////////////////////////////////////////////////////////////////
const setProfessorSchedule = (state, action) => {
    return updateObject(state, { professorSchedule: action.schedule });
};

/////////////////////////////////////////////////////////////////////////////
// PROFESSOR FREE HOURS
/////////////////////////////////////////////////////////////////////////////
const getProfessorFreeHoursStart = (state, action) => {
    return updateObject(state, {
        professorAvailability: [],
        isProfessorAvailabilityLoading: true,

        message: null,
        messageType: null,
    });
};

const getProfessorFreeHoursSuccess = (state, action) => {
    let freeHours = [];
    if (action.availability) {
        if (action.availability[0].is_all_day === false) {
            const selectedWeekDay = action.chosenDate.getDay() === 0 ? 7 : action.chosenDate.getDay();
            const hoursPerDay = state.schoolClassSettings ? state.schoolClassSettings.hours_daily[selectedWeekDay - 1] : 7;
            const professorBusyHours = [...action.availability[0].presence_hours, ...action.availability[0].absence_hours];
            freeHours = [...Array(hoursPerDay).keys()].map(elem => elem + 1).filter(elem => !professorBusyHours.includes(elem));
        }
    }
    return updateObject(state, {
        professorAvailability: freeHours,
        isProfessorAvailabilityLoading: false,
    });
};

const getProfessorFreeHoursFail = (state, action) => {
    return updateObject(state, {
        isProfessorAvailabilityLoading: false,
        // message: action.errorMessage,
        // messageType: 'error'
    });
};

/////////////////////////////////////////////////////////////////////////////
// PERM
/////////////////////////////////////////////////////////////////////////////
const saveAbsentProfessorAndPermStart = (state, action) => {
    return updateObject(state, {
        loading: true,

        message: null,
        messageType: null,
    });
};

const saveAbsentProfessorAndPermSuccess = (state, action) => {
    return updateObject(state, {
        showPermProfessorModal: false,
        loading: false,
        message: 'Permesso Docente salvato con successo',
        messageType: 'success'
    });
};

const saveAbsentProfessorAndPermFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        message: action.errorMessage,
        messageType: 'error'
    });
};

/////////////////////////////////////////////////////////////////////////////
// OTHERS
/////////////////////////////////////////////////////////////////////////////
const setShowPermProfessorModal = (state, action) => {
    return updateObject(state, { showPermProfessorModal: action.show });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ANY_HOUR_PROFESSORS_AVAILABLE_START: return getAnyHourProfessorsAvailableStart(state, action);
        case GET_ANY_HOUR_PROFESSORS_AVAILABLE_SUCCESS: return getAnyHourProfessorsAvailableSuccess(state, action);
        case GET_ANY_HOUR_PROFESSORS_AVAILABLE_FAIL: return getAnyHourProfessorsAvailableFail(state, action);

        case SET_PROFESSOR_SCHEDULE: return setProfessorSchedule(state, action);

        case GET_PROFESSOR_FREE_HOURS_START: return getProfessorFreeHoursStart(state, action);
        case GET_PROFESSOR_FREE_HOURS_SUCCESS: return getProfessorFreeHoursSuccess(state, action);
        case GET_PROFESSOR_FREE_HOURS_FAIL: return getProfessorFreeHoursFail(state, action);

        case SAVE_ABSENT_PROFESSOR_AND_PERM_START: return saveAbsentProfessorAndPermStart(state, action);
        case SAVE_ABSENT_PROFESSOR_AND_PERM_SUCCESS: return saveAbsentProfessorAndPermSuccess(state, action);
        case SAVE_ABSENT_PROFESSOR_AND_PERM_FAIL: return saveAbsentProfessorAndPermFail(state, action);

        case SET_SHOW_PERM_PROFESSOR_MODAL: return setShowPermProfessorModal(state, action);
        default: return state;
    }
};

export default reducer;