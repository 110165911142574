export * from "./menu/actions";
export * from "./settings/actions";
export * from "./allDayAbsentClass/actions";
export * from "./absentProfessors/actions";
export * from "./permProfessor/actions";
export * from "./replacements/actions";
export * from "./schedule/actions";
export * from "./summary/actions";
export * from "./professorAvailability/actions";
export * from "./professorsInfo/actions";
export * from "./options/actions";
export * from "./loadSchedule/actions";
export * from "./support/actions";
export * from "./boardMeeting/actions";
export * from "./auth/actions";
export * from "./adminsInfo/actions";
export * from "./manager/actions";

/* AUTH */
export const AUTH_START = 'AUTH_START'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAIL = 'AUTH_FAIL'

export const AUTH_LOGOUT_START = 'AUTH_LOGOUT_START'
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS'
export const AUTH_LOGOUT_FAIL = 'AUTH_LOGOUT_FAIL'

export const AUTH_LOGOUT = 'AUTH_LOGOUT'

export const SIGNUP_START = 'SIGNUP_START'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_FAIL = 'SIGNUP_FAIL'

export const RESET_PASSWORD_START = 'RESET_PASSWORD_START'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL'

export const FORGOT_PASSWORD_START = 'FORGOT_PASSWORD_START'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL'

export const UPDATE_TODOS = 'UPDATE_TODOS'

export const REFRESH_JWT_TOKEN_START = 'REFRESH_JWT_TOKEN_START'
export const REFRESH_JWT_TOKEN_END = 'REFRESH_JWT_TOKEN_END'

export const ADMIN_SIGNUP_START = 'ADMIN_SIGNUP_START'
export const ADMIN_SIGNUP_SUCCESS = 'ADMIN_SIGNUP_SUCCESS'
export const ADMIN_SIGNUP_FAIL = 'ADMIN_SIGNUP_FAIL'

export const ASK_NEW_LINK_START = 'ASK_NEW_LINK_START'
export const ASK_NEW_LINK_SUCCESS = 'ASK_NEW_LINK_SUCCESS'
export const ASK_NEW_LINK_FAIL = 'ASK_NEW_LINK_FAIL'

/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

/* SCHOOL CLASS */
export const GET_AVAILABLE_CLASSES_START = 'GET_AVAILABLE_CLASSES_START'
export const GET_AVAILABLE_CLASSES_SUCCESS = 'GET_AVAILABLE_CLASSES_SUCCESS'
export const GET_AVAILABLE_CLASSES_FAIL = 'GET_AVAILABLE_CLASSES_FAIL'

export const SAVE_ABSENT_CLASS_START = 'SAVE_ABSENT_CLASS_START'
export const SAVE_ABSENT_CLASS_SUCCESS = 'SAVE_ABSENT_CLASS_SUCCESS'
export const SAVE_ABSENT_CLASS_FAILED = 'SAVE_ABSENT_CLASS_FAILED'

export const GET_NOT_ABSENT_PROFESSORS_START = 'GET_NOT_ABSENT_PROFESSORS_START'
export const GET_NOT_ABSENT_PROFESSORS_SUCCESS = 'GET_NOT_ABSENT_PROFESSORS_SUCCESS'
export const GET_NOT_ABSENT_PROFESSORS_FAIL = 'GET_NOT_ABSENT_PROFESSORS_FAIL'

/* PROFESSOR AVAILABILITY */
export const GET_PROFESSORS_ANY_HOUR_AVAILABLE_START = 'GET_PROFESSORS_ANY_HOUR_AVAILABLE_START'
export const GET_PROFESSORS_ANY_HOUR_AVAILABLE_SUCCESS = 'GET_PROFESSORS_ANY_HOUR_AVAILABLE_SUCCESS'
export const GET_PROFESSORS_ANY_HOUR_AVAILABLE_FAIL = 'GET_PROFESSORS_ANY_HOUR_AVAILABLE_FAIL'

export const GET_ANY_HOUR_PROFESSORS_AVAILABLE_START = 'GET_ANY_HOUR_PROFESSORS_AVAILABLE_START'
export const GET_ANY_HOUR_PROFESSORS_AVAILABLE_SUCCESS = 'GET_ANY_HOUR_PROFESSORS_AVAILABLE_SUCCESS'
export const GET_ANY_HOUR_PROFESSORS_AVAILABLE_FAIL = 'GET_ANY_HOUR_PROFESSORS_AVAILABLE_FAIL'

export const GET_PROFESSOR_FREE_HOURS_START = 'GET_PROFESSOR_FREE_HOURS_START'
export const GET_PROFESSOR_FREE_HOURS_SUCCESS = 'GET_PROFESSOR_FREE_HOURS_SUCCESS'
export const GET_PROFESSOR_FREE_HOURS_FAIL = 'GET_PROFESSOR_FREE_HOURS_FAIL'

export const GET_PROFESSORS_AVAILABLE_START = 'GET_PROFESSORS_AVAILABLE_START'
export const GET_PROFESSORS_AVAILABLE_SUCCESS = 'GET_PROFESSORS_AVAILABLE_SUCCESS'
export const GET_PROFESSORS_AVAILABLE_FAIL = 'GET_PROFESSORS_AVAILABLE_FAIL'

/* PROFESSOR ABSENCE */
export const SAVE_ABSENT_PROFESSOR_AND_PERM_START = 'SAVE_ABSENT_PROFESSOR_AND_PERM_START'
export const SAVE_ABSENT_PROFESSOR_AND_PERM_SUCCESS = 'SAVE_ABSENT_PROFESSOR_AND_PERM_SUCCESS'
export const SAVE_ABSENT_PROFESSOR_AND_PERM_FAIL = 'SAVE_ABSENT_PROFESSOR_AND_PERM_FAIL'

export const SAVE_ABSENT_PROFESSORS_START = 'SAVE_ABSENT_PROFESSORS_START'
export const SAVE_ABSENT_PROFESSORS_SUCCESS = 'SAVE_ABSENT_PROFESSORS_SUCCESS'
export const SAVE_ABSENT_PROFESSORS_FAIL = 'SAVE_ABSENT_PROFESSORS_FAIL'

export const GET_PROFESSOR_ABSENCES_START = 'GET_PROFESSOR_ABSENCES_START'
export const GET_PROFESSOR_ABSENCES_SUCCESS = 'GET_PROFESSOR_ABSENCES_SUCCESS'
export const GET_PROFESSOR_ABSENCES_FAIL = 'GET_PROFESSOR_ABSENCES_FAIL'

export const DELETE_PROFESSOR_ABSENCE_START = 'DELETE_PROFESSOR_ABSENCE_START'
export const DELETE_PROFESSOR_ABSENCE_SUCCESS = 'DELETE_PROFESSOR_ABSENCE_SUCCESS'
export const DELETE_PROFESSOR_ABSENCE_FAIL = 'DELETE_PROFESSOR_ABSENCE_FAIL'

export const DELETE_ALL_PROFESSOR_ABSENCES_START = 'DELETE_ALL_PROFESSOR_ABSENCES_START'
export const DELETE_ALL_PROFESSOR_ABSENCES_SUCCESS = 'DELETE_ALL_PROFESSOR_ABSENCES_SUCCESS'
export const DELETE_ALL_PROFESSOR_ABSENCES_FAIL = 'DELETE_ALL_PROFESSOR_ABSENCES_FAIL'

/* SCHEDULE */
export const GET_SCHEDULE_START = 'GET_SCHEDULE_START'
export const GET_SCHEDULE_SUCCESS = 'GET_SCHEDULE_SUCCESS'
export const GET_SCHEDULE_FAIL = 'GET_SCHEDULE_FAIL'

export const SET_PROFESSOR_SCHEDULE = 'SET_PROFESSOR_SCHEDULE'

/* REPLACMENTS */
export const COMPUTE_REPLACEMENTS_START = 'COMPUTE_REPLACEMENTS_START'
export const COMPUTE_REPLACEMENTS_SUCCESS = 'COMPUTE_REPLACEMENTS_SUCCESS'
export const COMPUTE_REPLACEMENTS_FAIL = 'COMPUTE_REPLACEMENTS_FAIL'

/* SCHOOL CLASS ABSENCE */
export const GET_SCHOOL_CLASS_ABSENCES_START = 'GET_SCHOOL_CLASS_ABSENCES_START'
export const GET_SCHOOL_CLASS_ABSENCES_SUCCESS = 'GET_SCHOOL_CLASS_ABSENCES_SUCCESS'
export const GET_SCHOOL_CLASS_ABSENCES_FAIL = 'GET_SCHOOL_CLASS_ABSENCES_FAIL'

export const DELETE_SCHOOL_CLASS_ABSENCE_START = 'DELETE_SCHOOL_CLASS_ABSENCE_START'
export const DELETE_SCHOOL_CLASS_ABSENCE_SUCCESS = 'DELETE_SCHOOL_CLASS_ABSENCE_SUCCESS'
export const DELETE_SCHOOL_CLASS_ABSENCE_FAIL = 'DELETE_SCHOOL_CLASS_ABSENCE_FAIL'

export const DELETE_ALL_SCHOOL_CLASS_ABSENCES_START = 'DELETE_ALL_SCHOOL_CLASS_ABSENCES_START'
export const DELETE_ALL_SCHOOL_CLASS_ABSENCES_SUCCESS = 'DELETE_ALL_SCHOOL_CLASS_ABSENCES_SUCCESS'
export const DELETE_ALL_SCHOOL_CLASS_ABSENCES_FAIL = 'DELETE_ALL_SCHOOL_CLASS_ABSENCES_FAIL'

/* PROFESSOR PERM */
export const GET_PROFESSOR_PERMS_START = 'GET_PROFESSOR_PERMS_START'
export const GET_PROFESSOR_PERMS_SUCCESS = 'GET_PROFESSOR_PERMS_SUCCESS'
export const GET_PROFESSOR_PERMS_FAIL = 'GET_PROFESSOR_PERMS_FAIL'

export const DELETE_PROFESSOR_PERM_START = 'DELETE_PROFESSOR_PERM_START'
export const DELETE_PROFESSOR_PERM_SUCCESS = 'DELETE_PROFESSOR_PERM_SUCCESS'
export const DELETE_PROFESSOR_PERM_FAIL = 'DELETE_PROFESSOR_PERM_FAIL'

export const DELETE_ALL_PROFESSOR_PERMS_START = 'DELETE_ALL_PROFESSOR_PERMS_START'
export const DELETE_ALL_PROFESSOR_PERMS_SUCCESS = 'DELETE_ALL_PROFESSOR_PERMS_SUCCESS'
export const DELETE_ALL_PROFESSOR_PERMS_FAIL = 'DELETE_ALL_PROFESSOR_PERMS_FAIL'

/* PROFESSOR TO SUBJECT */
export const GET_PROFESSOR_TO_SUBJECTS_START = 'GET_PROFESSOR_TO_SUBJECTS_START'
export const GET_PROFESSOR_TO_SUBJECTS_SUCCESS = 'GET_PROFESSOR_TO_SUBJECTS_SUCCESS'
export const GET_PROFESSOR_TO_SUBJECTS_FAIL = 'GET_PROFESSOR_TO_SUBJECTS_FAIL'

export const REGISTER_PROFESSOR_TO_SUBJECTS_START = 'REGISTER_PROFESSOR_TO_SUBJECTS_START'
export const REGISTER_PROFESSOR_TO_SUBJECTS_SUCCESS = 'REGISTER_PROFESSOR_TO_SUBJECTS_SUCCESS'
export const REGISTER_PROFESSOR_TO_SUBJECTS_FAIL = 'REGISTER_PROFESSOR_TO_SUBJECTS_FAIL'

/* SCHOOL SETTINGS */
export const GET_SCHOOL_SETTINGS_START = 'GET_SCHOOL_SETTINGS_START'
export const GET_SCHOOL_SETTINGS_SUCCESS = 'GET_SCHOOL_SETTINGS_SUCCESS'
export const GET_SCHOOL_SETTINGS_FAIL = 'GET_SCHOOL_SETTINGS_FAIL'

export const UPDATE_SCHOOL_SETTINGS_START = 'UPDATE_SCHOOL_SETTINGS_START'
export const UPDATE_SCHOOL_SETTINGS_SUCCESS = 'UPDATE_SCHOOL_SETTINGS_SUCCESS'
export const UPDATE_SCHOOL_SETTINGS_FAIL = 'UPDATE_SCHOOL_SETTINGS_FAIL'

export const CREATE_NEW_ACADEMIC_YEAR_START = 'CREATE_NEW_ACADEMIC_YEAR_START'
export const CREATE_NEW_ACADEMIC_YEAR_SUCCESS = 'CREATE_NEW_ACADEMIC_YEAR_SUCCESS'
export const CREATE_NEW_ACADEMIC_YEAR_FAIL = 'CREATE_NEW_ACADEMIC_YEAR_FAIL'

export const CREATE_NEW_SUBJECT_START = 'CREATE_NEW_SUBJECT_START'
export const CREATE_NEW_SUBJECT_SUCCESS = 'CREATE_NEW_SUBJECT_SUCCESS'
export const CREATE_NEW_SUBJECT_FAIL = 'CREATE_NEW_SUBJECT_FAIL'

/* LOAD SCHEDULE */
export const LOAD_SCHEDULE_START = 'LOAD_SCHEDULE_START'
export const LOAD_SCHEDULE_SUCCESS = 'LOAD_SCHEDULE_SUCCESS'
export const LOAD_SCHEDULE_FAIL = 'LOAD_SCHEDULE_FAIL'

export const CONFIRM_NEW_SCHEDULE_START = 'CONFIRM_NEW_SCHEDULE_START'
export const CONFIRM_NEW_SCHEDULE_SUCCESS = 'CONFIRM_NEW_SCHEDULE_SUCCESS'
export const CONFIRM_NEW_SCHEDULE_FAIL = 'CONFIRM_NEW_SCHEDULE_FAIL'

export const RESET_SCHOOL_START = 'RESET_SCHOOL_START'
export const RESET_SCHOOL_SUCCESS = 'RESET_SCHOOL_SUCCESS'
export const RESET_SCHOOL_FAIL = 'RESET_SCHOOL_FAIL'

/* SUPPORT */
export const SUBMIT_FEEDBACK_START = 'SUBMIT_FEEDBACK_START'
export const SUBMIT_FEEDBACK_SUCCESS = 'SUBMIT_FEEDBACK_SUCCESS'
export const SUBMIT_FEEDBACK_FAIL = 'SUBMIT_FEEDBACK_FAIL'

/* BOARD MEETING */
export const GET_BOARD_MEETING_START = 'GET_BOARD_MEETING_START'
export const GET_BOARD_MEETING_SUCCESS = 'GET_BOARD_MEETING_SUCCESS'
export const GET_BOARD_MEETING_FAIL = 'GET_BOARD_MEETING_FAIL'

export const GET_BOARD_MEETING_PRINT_START = 'GET_BOARD_MEETING_PRINT_START'
export const GET_BOARD_MEETING_PRINT_SUCCESS = 'GET_BOARD_MEETING_PRINT_SUCCESS'
export const GET_BOARD_MEETING_PRINT_FAIL = 'GET_BOARD_MEETING_PRINT_FAIL'

export const GET_SUBJECTS_START = 'GET_SUBJECTS_START'
export const GET_SUBJECTS_SUCCESS = 'GET_SUBJECTS_SUCCESS'
export const GET_SUBJECTS_FAIL = 'GET_SUBJECTS_FAIL'

export const GET_SCHOOL_CLASSES_START = 'GET_SCHOOL_CLASSES_START'
export const GET_SCHOOL_CLASSES_SUCCESS = 'GET_SCHOOL_CLASSES_SUCCESS'
export const GET_SCHOOL_CLASSES_FAIL = 'GET_SCHOOL_CLASSES_FAIL'

/* RESOLUTIONS */
export const GET_RESOLUTIONS_START = 'GET_RESOLUTIONS_START'
export const GET_RESOLUTIONS_SUCCESS = 'GET_RESOLUTIONS_SUCCESS'
export const GET_RESOLUTIONS_FAIL = 'GET_RESOLUTIONS_FAIL'

/* MODALS */
export const SET_SHOW_ABSENT_CLASS_MODAL = 'SET_SHOW_ABSENT_CLASS_MODAL'
export const SET_SHOW_ABSENT_PROFESSOR_MODAL = 'SET_SHOW_ABSENT_PROFESSOR_MODAL'
export const SET_SHOW_PERM_PROFESSOR_MODAL = 'SET_SHOW_PERM_PROFESSOR_MODAL'
export const SET_SHOW_REPLACEMENT_CHECKOUT_MODAL = 'SET_SHOW_REPLACEMENT_CHECKOUT_MODAL'
export const SHOW_SCHOOL_CLASS_ABSENCE_DELETE_MODAL = 'SHOW_SCHOOL_CLASS_ABSENCE_DELETE_MODAL'
export const SHOW_PROFESSOR_ABSENCE_DELETE_MODAL = 'SHOW_PROFESSOR_ABSENCE_DELETE_MODAL'
export const SHOW_PROFESSOR_PERM_DELETE_MODAL = 'SHOW_PROFESSOR_PERM_DELETE_MODAL'
export const SHOW_PROFESSOR_INFO_MODAL = 'SHOW_PROFESSOR_INFO_MODAL'
export const SHOW_ADMIN_INFO_MODAL = 'SHOW_ADMIN_INFO_MODAL'
export const SHOW_LOAD_SCHEDULE_MODAL = 'SHOW_LOAD_SCHEDULE_MODAL'
export const SHOW_RESET_SCHOOL_MODAL = 'SHOW_RESET_SCHOOL_MODAL'
export const SET_SHOW_CREATE_NEW_ACADEMIC_YEAR_MODAL = 'SET_SHOW_CREATE_NEW_ACADEMIC_YEAR_MODAL'
export const SET_SHOW_SUBJECT_MODAL = 'SET_SHOW_SUBJECT_MODAL'

/* ADMINS */
export const GET_ADMINS_START = 'GET_ADMINS_START'
export const GET_ADMINS_SUCCESS = 'GET_ADMINS_SUCCESS'
export const GET_ADMINS_FAIL = 'GET_ADMINS_FAIL'

export const REGISTER_ADMIN_START = 'REGISTER_ADMIN_START'
export const REGISTER_ADMIN_SUCCESS = 'REGISTER_ADMIN_SUCCESS'
export const REGISTER_ADMIN_FAIL = 'REGISTER_ADMIN_FAIL'

export const REMOVE_ADMIN_START = 'REMOVE_ADMIN_START'
export const REMOVE_ADMIN_SUCCESS = 'REMOVE_ADMIN_SUCCESS'
export const REMOVE_ADMIN_FAIL = 'REMOVE_ADMIN_FAIL'

/* MANAGER */
export const GET_SCHOOL_REQUESTS_START = 'GET_SCHOOL_REQUESTS_START'
export const GET_SCHOOL_REQUESTS_SUCCESS = 'GET_SCHOOL_REQUESTS_SUCCESS'
export const GET_SCHOOL_REQUESTS_FAIL = 'GET_SCHOOL_REQUESTS_FAIL'

export const GET_SCHOOL_ARCHIVE_START = 'GET_SCHOOL_ARCHIVE_START'
export const GET_SCHOOL_ARCHIVE_SUCCESS = 'GET_SCHOOL_ARCHIVE_SUCCESS'
export const GET_SCHOOL_ARCHIVE_FAIL = 'GET_SCHOOL_ARCHIVE_FAIL'

export const MAKE_DECISION_ON_SCHOOL_REQUEST_START = 'MAKE_DECISION_ON_SCHOOL_REQUEST_START'
export const MAKE_DECISION_ON_SCHOOL_REQUEST_SUCCESS = 'MAKE_DECISION_ON_SCHOOL_REQUEST_SUCCESS'
export const MAKE_DECISION_ON_SCHOOL_REQUEST_FAIL = 'MAKE_DECISION_ON_SCHOOL_REQUEST_FAIL'

export const SHOW_SCHOOL_REQUEST_INFO_MODAL = 'SHOW_SCHOOL_REQUEST_INFO_MODAL'