import {
    GET_SCHOOL_SETTINGS_START,
    GET_SCHOOL_SETTINGS_SUCCESS,
    GET_SCHOOL_SETTINGS_FAIL,
    UPDATE_SCHOOL_SETTINGS_START,
    UPDATE_SCHOOL_SETTINGS_SUCCESS,
    UPDATE_SCHOOL_SETTINGS_FAIL,
    CREATE_NEW_ACADEMIC_YEAR_START,
    CREATE_NEW_ACADEMIC_YEAR_SUCCESS,
    CREATE_NEW_ACADEMIC_YEAR_FAIL,
    CREATE_NEW_SUBJECT_START,
    CREATE_NEW_SUBJECT_SUCCESS,
    CREATE_NEW_SUBJECT_FAIL,
    SET_SHOW_CREATE_NEW_ACADEMIC_YEAR_MODAL,
    SET_SHOW_SUBJECT_MODAL,
} from '../actions';

import { updateObject } from '../utility';


const initialState = {
    schoolSettings: {},

    loadingCreateNewAcademicYear: false,
    showCreateNewAcademicYearModal: false,
    loadingCreateNewSubject: false,
    showSubjectModal: false,

    loading: false,
    message: null,
    messageType: null,
}

const getSchoolSettingsStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        message: null,
        messageType: null,
    });
};

const getSchoolSettingsSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        schoolSettings: action.schoolSettings,
    });
};

const getSchoolSettingsFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        message: action.error,
        messageType: 'error',
    });
};

const updateSchoolSettingsStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        message: null,
        messageType: null,
    });
};

const updateSchoolSettingsSuccess = (state, action) => {
    return updateObject(state, {
        schoolSettings: { ...action.schoolSettings },
        loading: false,
        message: 'Opzioni aggiornate con successo',
        messageType: 'success',
    });
};

const updateSchoolSettingsFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        message: action.error,
        messageType: 'error',
    });
};

const createNewAcademicYearStart = (state, action) => {
    return updateObject(state, {
        loadingCreateNewAcademicYear: true,
        message: null,
        messageType: null,
    });
};

const createNewAcademicYearSuccess = (state, action) => {
    return updateObject(state, {
        loadingCreateNewAcademicYear: false,
        showCreateNewAcademicYearModal: false,
        message: 'Nuovo anno scolastico creato con successo',
        messageType: 'success',
    });
};

const createNewAcademicYearFail = (state, action) => {
    return updateObject(state, {
        loadingCreateNewAcademicYear: false,
        showCreateNewAcademicYearModal: false,
        message: action.errorMessage,
        messageType: 'error',
    });
};

const createNewSubjectStart = (state, action) => {
    return updateObject(state, {
        loadingCreateNewSubject: true,
        message: null,
        messageType: null,
    });
};

const createNewSubjectSuccess = (state, action) => {
    return updateObject(state, {
        loadingCreateNewSubject: false,
        showSubjectModal: false,
        message: 'Nuova materia creata con successo',
        messageType: 'success',
        schoolSettings: { ...action.schoolSettings },
    });
};

const createNewSubjectFail = (state, action) => {
    return updateObject(state, {
        loadingCreateNewSubject: false,
        showSubjectModal: false,
        message: action.errorMessage,
        messageType: 'error',
    });
};

const setShowCreateNewAcademicYearModal = (state, action) => {
    return updateObject(state, { showCreateNewAcademicYearModal: action.show });
};

const setShowSubjectModal = (state, action) => {
    return updateObject(state, { showSubjectModal: action.show });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SCHOOL_SETTINGS_START: return getSchoolSettingsStart(state, action);
        case GET_SCHOOL_SETTINGS_SUCCESS: return getSchoolSettingsSuccess(state, action);
        case GET_SCHOOL_SETTINGS_FAIL: return getSchoolSettingsFail(state, action);
        case UPDATE_SCHOOL_SETTINGS_START: return updateSchoolSettingsStart(state, action);
        case UPDATE_SCHOOL_SETTINGS_SUCCESS: return updateSchoolSettingsSuccess(state, action);
        case UPDATE_SCHOOL_SETTINGS_FAIL: return updateSchoolSettingsFail(state, action);
        case CREATE_NEW_ACADEMIC_YEAR_START: return createNewAcademicYearStart(state, action);
        case CREATE_NEW_ACADEMIC_YEAR_SUCCESS: return createNewAcademicYearSuccess(state, action);
        case CREATE_NEW_ACADEMIC_YEAR_FAIL: return createNewAcademicYearFail(state, action);
        case CREATE_NEW_SUBJECT_START: return createNewSubjectStart(state, action);
        case CREATE_NEW_SUBJECT_SUCCESS: return createNewSubjectSuccess(state, action);
        case CREATE_NEW_SUBJECT_FAIL: return createNewSubjectFail(state, action);
        case SET_SHOW_CREATE_NEW_ACADEMIC_YEAR_MODAL: return setShowCreateNewAcademicYearModal(state, action);
        case SET_SHOW_SUBJECT_MODAL: return setShowSubjectModal(state, action);
        default: return state;
    }
}

export default reducer;