import {
    GET_ADMINS_START,
    GET_ADMINS_SUCCESS,
    GET_ADMINS_FAIL,
    REGISTER_ADMIN_START,
    REGISTER_ADMIN_SUCCESS,
    REGISTER_ADMIN_FAIL,
    REMOVE_ADMIN_START,
    REMOVE_ADMIN_SUCCESS,
    REMOVE_ADMIN_FAIL,
    SHOW_ADMIN_INFO_MODAL,
} from '../actions';


import { updateObject } from '../utility';

const initialState = {
    admins: [],

    showAdminInfoModal: false,
    isLoadingOnRegistering: false,

    loading: false,
    message: null,
    messageType: null,
}

const getAdminsStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        message: null,
        messageType: null,
    });
};

const getAdminsSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        admins: action.admins,
    });
};

const getAdminsFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        message: action.error,
        messageType: 'error',
    });
};

const registerAdminStart = (state, action) => {
    return updateObject(state, {
        isLoadingOnRegistering: true,
        message: null,
        messageType: null,
    });
};

const registerAdminSuccess = (state, action) => {
    return updateObject(state, {
        isLoadingOnRegistering: false,
        admins: action.admins,
        message: 'Nuovo ammistratore aggiunto con successo',
        messageType: 'success',
        showAdminInfoModal: false,
    });
};

const registerAdminFail = (state, action) => {
    return updateObject(state, {
        isLoadingOnRegistering: false,

        message: action.errorMessage,
        messageType: 'error',
    });
};

const removeAdminStart = (state, action) => {
    return updateObject(state, {
        isLoadingOnRegistering: true,
        message: null,
        messageType: null,
    });
};

const removeAdminSuccess = (state, action) => {
    return updateObject(state, {
        isLoadingOnRegistering: false,
        admins: action.admins,
        message: 'Amministratore rimosso con successo',
        messageType: 'success',
        showAdminInfoModal: false,
    });
};

const removeAdminFail = (state, action) => {
    return updateObject(state, {
        isLoadingOnRegistering: false,

        message: action.errorMessage,
        messageType: 'error',
    });
};

/////////////////////////////////////////////////////////////////////////////
// OTHER
/////////////////////////////////////////////////////////////////////////////
const setShowAdminInfoModal = (state, action) => {
    return updateObject(state, {
        showAdminInfoModal: action.show,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ADMINS_START: return getAdminsStart(state, action);
        case GET_ADMINS_SUCCESS: return getAdminsSuccess(state, action);
        case GET_ADMINS_FAIL: return getAdminsFail(state, action);
        case REGISTER_ADMIN_START: return registerAdminStart(state, action);
        case REGISTER_ADMIN_SUCCESS: return registerAdminSuccess(state, action);
        case REGISTER_ADMIN_FAIL: return registerAdminFail(state, action);
        case REMOVE_ADMIN_START: return removeAdminStart(state, action);
        case REMOVE_ADMIN_SUCCESS: return removeAdminSuccess(state, action);
        case REMOVE_ADMIN_FAIL: return removeAdminFail(state, action);
        case SHOW_ADMIN_INFO_MODAL: return setShowAdminInfoModal(state, action);
        default: return state;
    }
}

export default reducer;