import {
    GET_SCHEDULE_START,
    GET_SCHEDULE_SUCCESS,
    GET_SCHEDULE_FAIL,
} from '../actions';

import { updateObject } from '../utility';

const initialState = {
    schedule: [],
    weekDays: null,
    dailyHours: null,
    lessonsTime: null,

    loading: false,
}

const getScheduleStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const getScheduleSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        schedule: action.data.schedule,
        weekDays: action.data.school_settings.days_per_week,
        dailyHours: action.data.school_settings.hours_daily,
        lessonsTime: action.data.school_settings.school_lesson_hours,
    });
};

const getScheduleFail = (state, action) => {
    return updateObject(state, { loading: false });
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SCHEDULE_START: return getScheduleStart(state, action);
        case GET_SCHEDULE_SUCCESS: return getScheduleSuccess(state, action);
        case GET_SCHEDULE_FAIL: return getScheduleFail(state, action);
        default: return state;
    }
}

export default reducer;