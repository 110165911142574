import { combineReducers } from 'redux';
import menu from './menu/reducer';
import settings from './settings/reducer';
import allDayAbsentClass from './allDayAbsentClass/reducer';
import absentProfessors from './absentProfessors/reducer';
import permProfessor from './permProfessor/reducer';
import replacements from './replacements/reducer';
import schedule from './schedule/reducer';
import summary from './summary/reducer';
import professorAvailability from './professorAvailability/reducer';
import professorsInfo from './professorsInfo/reducer';
import options from './options/reducer';
import loadSchedule from './loadSchedule/reducer';
import support from './support/reducer';
import boardMeeting from './boardMeeting/reducer';
import auth from './auth/reducer';
import adminsInfo from './adminsInfo/reducer';
import manager from './manager/reducer';

const reducers = combineReducers({
  allDayAbsentClass,
  absentProfessors,
  permProfessor,
  replacements,
  schedule,
  summary,
  professorAvailability,
  professorsInfo,
  options,
  loadSchedule,
  support,
  auth,
  menu,
  settings,
  boardMeeting,
  adminsInfo,
  manager,
});

export default reducers;