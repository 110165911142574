import {
    LOAD_SCHEDULE_START,
    LOAD_SCHEDULE_SUCCESS,
    LOAD_SCHEDULE_FAIL,
    CONFIRM_NEW_SCHEDULE_START,
    CONFIRM_NEW_SCHEDULE_SUCCESS,
    CONFIRM_NEW_SCHEDULE_FAIL,
    RESET_SCHOOL_START,
    RESET_SCHOOL_SUCCESS,
    RESET_SCHOOL_FAIL,
    SHOW_LOAD_SCHEDULE_MODAL,
    SHOW_RESET_SCHOOL_MODAL,
} from '../actions';

import { updateObject } from '../utility';


const initialState = {
    showModal: false,
    scheduleType: null,
    requestStatus: null,
    schedule: null,
    weekDays: null,
    dailyHours: null,
    lessonsTime: null,
    oldProfessors: null,
    genericErrors: null,
    challengeDateFile: null,
    challengeTimeFile: null,

    loading: false,
    message: null,
    messageType: null,

    showResetSchoolModal: false,
    loadingReset: false,
    messageReset: null,
    messageTypeReset: null,
}

const loadScheduleStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        message: null,
        messageType: null,
    });
};

const loadScheduleSuccess = (state, action) => {
    const requestStatus = action.data.status;
    let schedule = null;
    let weekDays = null;
    let dailyHours = null;
    let lessonsTime = null;
    let oldProfessors = null;
    let challengeDateFile = null;
    let challengeTimeFile = null;
    let message = null;
    let messageType = null;
    let loading = false;
    let showModal = true;
    let genericErrors = null;

    if (requestStatus === "challenge") {
        schedule = [...action.data.challenge.changes.schedule];
        weekDays = action.data.school_settings.days_per_week;
        dailyHours = action.data.school_settings.hours_daily;
        lessonsTime = action.data.school_settings.school_lesson_hours;
        challengeDateFile = action.data.challenge.date_file;
        challengeTimeFile = action.data.challenge.time_file;

        if (action.scheduleType === 1 || action.scheduleType === 2) {
            oldProfessors = { ...action.data.challenge.changes.professors };
        }
    } else if (requestStatus === "error") {
        schedule = [...action.data.errors.cell];
        weekDays = action.data.school_settings.weekdays;
        dailyHours = action.data.school_settings.daily_hours;
        lessonsTime = action.data.school_settings.lessons_time;
        genericErrors = [...action.data.errors.generic];
    } else if (requestStatus === "no_changes") {
        message = 'Nessun cambio individuato';
        messageType = 'info';
        showModal = false;
    } else if (requestStatus === 'success') {
        loading = false;
        showModal = false;
        message = 'Orario Salvato con Successo';
        messageType = 'success';
    }
    return updateObject(state, {
        loading: loading,
        showModal: showModal,
        requestStatus: requestStatus,
        schedule: schedule,
        weekDays: weekDays,
        dailyHours: dailyHours,
        lessonsTime: lessonsTime,
        oldProfessors: oldProfessors,
        challengeDateFile: challengeDateFile,
        challengeTimeFile: challengeTimeFile,
        message: message,
        messageType: messageType,
        genericErrors: genericErrors,
    });
};

const loadScheduleFail = (state, action) => {
    return updateObject(state, {
        message: action.errorMessage,
        messageType: 'error',
        loading: false,
    });
};

const confirmNewScheduleStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        message: null,
        messageType: null,
    });
};

const confirmNewScheduleSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        showModal: false,
        message: 'Orario Salvato con Successo',
        messageType: 'success',
    });
};

const confirmNewScheduleFail = (state, action) => {
    return updateObject(state, { 
        message: action.errorMessage,
        messageType: 'error',
        loading: false,
     });
};

const resetSchoolStart = (state, action) => {
    return updateObject(state, {
        loadingReset: true,
        messageReset: null,
        messageTypeReset: null,
    });
};

const resetSchoolSuccess = (state, action) => {
    return updateObject(state, {
        loadingReset: false,
        showResetSchoolModal: false,
        messageReset: action.message,
        messageTypeReset: 'success',
    });
};

const resetSchoolFail = (state, action) => {
    // TODO: add message
    return updateObject(state, { loadingReset: false });
};

const showLoadScheduleModal = (state, action) => {
    // When model is getting closed reset all params
    if (state.showModal === false && action.show === true) {
        return updateObject(state, {
            showModal: action.show,
            scheduleType: action.scheduleType,

            requestStatus: null,
            schedule: null,
            oldProfessors: null,
            genericErrors: null,
            challengeDateFile: null,
            challengeTimeFile: null,
        });
    }

    return updateObject(state, {
        showModal: action.show,
        scheduleType: action.scheduleType,
    });
};

const showResetSchoolModal = (state, action) => {
    return updateObject(state, { showResetSchoolModal: action.show });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_LOAD_SCHEDULE_MODAL: return showLoadScheduleModal(state, action);
        case SHOW_RESET_SCHOOL_MODAL: return showResetSchoolModal(state, action);
        case LOAD_SCHEDULE_START: return loadScheduleStart(state, action);
        case LOAD_SCHEDULE_SUCCESS: return loadScheduleSuccess(state, action);
        case LOAD_SCHEDULE_FAIL: return loadScheduleFail(state, action);
        case CONFIRM_NEW_SCHEDULE_START: return confirmNewScheduleStart(state, action);
        case CONFIRM_NEW_SCHEDULE_SUCCESS: return confirmNewScheduleSuccess(state, action);
        case CONFIRM_NEW_SCHEDULE_FAIL: return confirmNewScheduleFail(state, action);

        case RESET_SCHOOL_START: return resetSchoolStart(state, action);
        case RESET_SCHOOL_SUCCESS: return resetSchoolSuccess(state, action);
        case RESET_SCHOOL_FAIL: return resetSchoolFail(state, action);
        default: return state;
    }
}

export default reducer;