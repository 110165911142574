import {
    GET_BOARD_MEETING_START,
    GET_BOARD_MEETING_SUCCESS,
    GET_BOARD_MEETING_FAIL,
    GET_BOARD_MEETING_PRINT_START,
    GET_BOARD_MEETING_PRINT_SUCCESS,
    GET_BOARD_MEETING_PRINT_FAIL,
    GET_SUBJECTS_START,
    GET_SUBJECTS_SUCCESS,
    GET_SUBJECTS_FAIL,
    GET_SCHOOL_CLASSES_START,
    GET_SCHOOL_CLASSES_SUCCESS,
    GET_SCHOOL_CLASSES_FAIL,
} from '../actions';

import axios from '../../axios-professors';

export const getBoardMeetingStart = () => {
    return {
        type: GET_BOARD_MEETING_START
    }
};

export const getBoardMeetingSuccess = (boardMeeting) => {
    return {
        type: GET_BOARD_MEETING_SUCCESS,
        boardMeeting: boardMeeting,
    }
};

export const getBoardMeetingFail = () => {
    return {
        type: GET_BOARD_MEETING_FAIL
    }
};

export const getBoardMeetingPrintStart = () => {
    return {
        type: GET_BOARD_MEETING_PRINT_START
    }
};

export const getBoardMeetingPrintSuccess = () => {
    return {
        type: GET_BOARD_MEETING_PRINT_SUCCESS,
    }
};

export const getBoardMeetingPrintFail = (errorMessage) => {
    return {
        type: GET_BOARD_MEETING_PRINT_FAIL,
        errorMessage: errorMessage,
    }
};

export const getSubjectsStart = () => {
    return {
        type: GET_SUBJECTS_START
    }
};

export const getSubjectsSuccess = (subjects) => {
    return {
        type: GET_SUBJECTS_SUCCESS,
        subjects: subjects,
    }
};

export const getSubjectsFail = () => {
    return {
        type: GET_SUBJECTS_FAIL
    }
};

export const getSchoolClassesStart = () => {
    return {
        type: GET_SCHOOL_CLASSES_START
    }
};

export const getSchoolClassesSuccess = (schoolClasses) => {
    return {
        type: GET_SCHOOL_CLASSES_SUCCESS,
        schoolClasses: schoolClasses,
    }
};

export const getSchoolClassesFail = () => {
    return {
        type: GET_SCHOOL_CLASSES_FAIL
    }
};
/////////////////////////////////////////////////////////////////////////////
// OTHER
/////////////////////////////////////////////////////////////////////////////

////// MIDDLEWARES (or Async Functions)
export const getBoardMeeting = (data) => {
    return dispatch => {

        if (data.return_pdf) {
            dispatch(getBoardMeetingPrintStart());
            axios({
                method: 'post',
                url: '/v1/board_meeting',
                data: data,
                responseType: 'blob'
            })
                .then(response => {
                    var today = new Date();
                    var dd = String(today.getDate()).padStart(2, '0');
                    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                    var yyyy = today.getFullYear();
                    today = yyyy + '/' + mm + '/' + dd;

                    const file_name = 'consigli_di_classe_' + today + '.pdf'
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', file_name);
                    document.body.appendChild(link);
                    link.click();
                    dispatch(getBoardMeetingPrintSuccess());
                })
                .catch(error => {
                    let errorMessage = 'Qualcosa è andato storto. Per favore riprovare.';
                    if (error !== undefined && error.response !== undefined && error.response.data !== undefined && error.response.data.error !== undefined) {
                        errorMessage = error.response.data.error;
                    }
                    dispatch(getBoardMeetingPrintFail(errorMessage));
                })
        } else {
            dispatch(getBoardMeetingStart());
            axios.post('/v1/board_meeting', data)
                .then(response => {
                    const boardMeeting = response.data.board_meeting;
                    dispatch(getBoardMeetingSuccess(boardMeeting));
                })
                .catch(error => {
                    console.log('[getBoardMeeting] ERROR ', error);
                    dispatch(getBoardMeetingFail());
                })
        }
    }
};

export const getSubjects = () => {
    return dispatch => {
        dispatch(getSubjectsStart());
        axios.get('/v1/subject')
            .then(response => {
                const subjects = response.data.subjects
                    .sort((a, b) => {
                        var textA = a.name.toUpperCase();
                        var textB = b.name.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                dispatch(getSubjectsSuccess(subjects));
            })
            .catch(error => {
                console.log('[getSubjects] ERROR ', error);
                dispatch(getSubjectsFail());
            })
    }
};

export const getSchoolClasses = () => {
    return dispatch => {
        dispatch(getSchoolClassesStart());
        axios.get('/v1/school_class')
            .then(response => {
                const schoolClasses = response.data.school_classes
                    .sort((a, b) => {
                        var yearA = a.year;
                        var yearB = b.year;
                        if (yearA < yearB) return -1;
                        if (yearA > yearB) return 1;
                        var sectionA = a.section.toUpperCase();
                        var sectionB = b.section.toUpperCase();
                        return (sectionA < sectionB) ? -1 : (sectionA > sectionB) ? 1 : 0;
                    });
                dispatch(getSchoolClassesSuccess(schoolClasses));
            })
            .catch(error => {
                console.log('[getSchoolClasses] ERROR ', error);
                dispatch(getSchoolClassesFail());
            })
    }
};