import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URI,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true, // TODO: ONLY IN REFRESH CALLS
});

instance.interceptors.request.use(
    function (config) {
        const token = localStorage.getItem('token');
        if (token && !config.url.startsWith("https://scuolasmart-schedule-prod")) config.headers.Authorization = `JWT ${token}`;
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export default instance;
