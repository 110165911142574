import {
    GET_AVAILABLE_CLASSES_START,
    GET_AVAILABLE_CLASSES_FAIL,
    GET_AVAILABLE_CLASSES_SUCCESS,
    SAVE_ABSENT_CLASS_START,
    SAVE_ABSENT_CLASS_SUCCESS,
    SAVE_ABSENT_CLASS_FAILED,
    GET_NOT_ABSENT_PROFESSORS_START,
    GET_NOT_ABSENT_PROFESSORS_SUCCESS,
    GET_NOT_ABSENT_PROFESSORS_FAIL,
    SET_SHOW_ABSENT_CLASS_MODAL,
} from '../actions';

import { updateObject } from '../utility';

const initialState = {
    availableClasses: [],
    notAbsentProfessors: [],
    schoolClassSettings: null,

    showAbsentClassModal: false,
    loading: false,
    availableClassesLoading: false,
    availableProfessorsLoading: false,

    message: null,
    messageType: null,
};

const saveAbsentClassStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        message: null,
        messageType: null,
    });
};

const saveAbsentClassSuccess = (state, action) => {
    return updateObject(state, {
        showAbsentClassModal: false,
        loading: false,
        availableClasses: [],
        notAbsentProfessors: [],
        message: 'Assenze classi salvata con successo',
        messageType: 'success',
    });
};

const saveAbsentClassFail = (state, action) => {
    return updateObject(state, {
        message: action.errorMessage,
        messageType: 'error',
        loading: false,
    });
};

/////////////////////////////////////////////////////////////////////////////
// SCHOOL CLASS AVAILABILITY
/////////////////////////////////////////////////////////////////////////////
const getAvailableSchoolClassesStart = (state, action) => {
    return updateObject(state, {
        availableClasses: [],
        availableClassesLoading: true,

        message: null,
        messageType: null,
    });
};

const getAvailableSchoolClassesSuccess = (state, action) => {
    return updateObject(state, {
        availableClasses: action.availableClasses,
        availableClassesLoading: false,
    });
};

const getAvailableSchoolClassesFail = (state, action) => {
    return updateObject(state, {
        availableClassesLoading: false,
        message: action.errorMessage,
        messageType: 'error',
    });
};

/////////////////////////////////////////////////////////////////////////////
// PROFESSOR
/////////////////////////////////////////////////////////////////////////////
const getNotAbsentProfessorsStart = (state, action) => {
    return updateObject(state, {
        notAbsentProfessors: [],
        availableProfessorsLoading: true,

        message: null,
        messageType: null,
    });
};

const getNotAbsentProfessorsSuccess = (state, action) => {
    const professors = action.professors
        .filter(availability => availability.is_all_day === false)
        .map(availability => availability.professor);
    return updateObject(state, {
        notAbsentProfessors: professors,
        availableProfessorsLoading: false,
        schoolClassSettings: action.schoolClassSettings,
    });
};

const getNotAbsentProfessorsFail = (state, action) => {
    return updateObject(state, {
        message: action.errorMessage,
        messageType: 'error',
        availableProfessorsLoading: false,
    });
};

/////////////////////////////////////////////////////////////////////////////
// OTHERS
/////////////////////////////////////////////////////////////////////////////
const setShowAbsentClassModal = (state, action) => {
    if (action.show === false) {
        return updateObject(state, {
            showAbsentClassModal: action.show,
            availableClasses: [],
            notAbsentProfessors: [],
        });
    }
    return updateObject(state, { showAbsentClassModal: action.show });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_AVAILABLE_CLASSES_START: return getAvailableSchoolClassesStart(state, action);
        case GET_AVAILABLE_CLASSES_SUCCESS: return getAvailableSchoolClassesSuccess(state, action);
        case GET_AVAILABLE_CLASSES_FAIL: return getAvailableSchoolClassesFail(state, action);
        case GET_NOT_ABSENT_PROFESSORS_START: return getNotAbsentProfessorsStart(state, action);
        case GET_NOT_ABSENT_PROFESSORS_SUCCESS: return getNotAbsentProfessorsSuccess(state, action);
        case GET_NOT_ABSENT_PROFESSORS_FAIL: return getNotAbsentProfessorsFail(state, action);
        case SAVE_ABSENT_CLASS_START: return saveAbsentClassStart(state, action);
        case SAVE_ABSENT_CLASS_SUCCESS: return saveAbsentClassSuccess(state, action);
        case SAVE_ABSENT_CLASS_FAILED: return saveAbsentClassFail(state, action);
        case SET_SHOW_ABSENT_CLASS_MODAL: return setShowAbsentClassModal(state, action);
        default: return state;
    }
};

export default reducer;