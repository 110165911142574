import {
    GET_PROFESSORS_AVAILABLE_START,
    GET_PROFESSORS_AVAILABLE_SUCCESS,
    GET_PROFESSORS_AVAILABLE_FAIL,
} from '../actions';

import { updateObject } from '../utility';


const initialState = {
    availableProfessors: [],
    isAvailableProfessorsLoading: false,
};

const getProfessorsAvailableStart = (state, action) => {
    return updateObject(state, {
        availableProfessors: [],
        isAvailableProfessorsLoading: true,
    });
};

const getProfessorsAvailableSuccess = (state, action) => {
    return updateObject(state, {
        availableProfessors: action.professors,
        isAvailableProfessorsLoading: false,
    });
};

const getProfessorsAvailableFail = (state, action) => {
    return updateObject(state, {
        isAvailableProfessorsLoading: false,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PROFESSORS_AVAILABLE_START: return getProfessorsAvailableStart(state, action);
        case GET_PROFESSORS_AVAILABLE_SUCCESS: return getProfessorsAvailableSuccess(state, action);
        case GET_PROFESSORS_AVAILABLE_FAIL: return getProfessorsAvailableFail(state, action);
        default: return state;
    }
};

export default reducer;