import {
    GET_AVAILABLE_CLASSES_START,
    GET_AVAILABLE_CLASSES_FAIL,
    GET_AVAILABLE_CLASSES_SUCCESS,
    SAVE_ABSENT_CLASS_START,
    SAVE_ABSENT_CLASS_SUCCESS,
    SAVE_ABSENT_CLASS_FAILED,
    GET_NOT_ABSENT_PROFESSORS_START,
    GET_NOT_ABSENT_PROFESSORS_SUCCESS,
    GET_NOT_ABSENT_PROFESSORS_FAIL,
    SET_SHOW_ABSENT_CLASS_MODAL,
} from '../actions';

import axios from '../../axios-professors';

/////////////////////////////////////////////////////////////////////////////
// SCHOOL CLASS
/////////////////////////////////////////////////////////////////////////////
export const getAvailableSchoolClassesStart = () => {
    return {
        type: GET_AVAILABLE_CLASSES_START
    }
};

export const getAvailableSchoolClassesFail = (errorMessage) => {
    return {
        type: GET_AVAILABLE_CLASSES_FAIL,
        errorMessage: errorMessage
    }
};

export const getAvailableSchoolClassesSuccess = (availableClasses) => {
    return {
        type: GET_AVAILABLE_CLASSES_SUCCESS,
        availableClasses: availableClasses
    }
};

export const saveAbsentClassStart = () => {
    return {
        type: SAVE_ABSENT_CLASS_START
    }
};

export const saveAbsentClassSuccess = () => {
    return {
        type: SAVE_ABSENT_CLASS_SUCCESS
    }
};

export const saveAbsentClassFail = (errorMessage) => {
    return {
        type: SAVE_ABSENT_CLASS_FAILED,
        errorMessage: errorMessage
    }
};

/////////////////////////////////////////////////////////////////////////////
// PROFESSOR
/////////////////////////////////////////////////////////////////////////////
export const getNotAbsentProfessorsStart = () => {
    return {
        type: GET_NOT_ABSENT_PROFESSORS_START
    }
};

export const getNotAbsentProfessorsSuccess = (professors, schoolClassSettings) => {
    return {
        type: GET_NOT_ABSENT_PROFESSORS_SUCCESS,
        professors: professors,
        schoolClassSettings: schoolClassSettings,
    }
};

export const getNotAbsentProfessorsFail = (errorMessage) => {
    return {
        type: GET_NOT_ABSENT_PROFESSORS_FAIL,
        errorMessage: errorMessage,
    }
};

/////////////////////////////////////////////////////////////////////////////
// OTHERS
/////////////////////////////////////////////////////////////////////////////
export const setShowAbsentClassModal = (show) => {
    return {
        type: SET_SHOW_ABSENT_CLASS_MODAL,
        show: show
    }
};

////// MIDDLEWARES (or Async Functions)
export const getNotAbsentProfessors = (chosenDate) => {
    return dispatch => {
        dispatch(getNotAbsentProfessorsStart());
        axios.get('/v1/professor/availability?date=' + chosenDate)
            .then(response => {
                const professors = response.data
                    .sort((a, b) => {
                        var textA = a.professor.fiscal_code.toUpperCase();
                        var textB = b.professor.fiscal_code.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                axios.get('/v1/school/settings')
                    .then(response => { 
                        const schoolClassSettings = response.data;
                        dispatch(getNotAbsentProfessorsSuccess(professors, schoolClassSettings));
                    })
                    .catch(error => {
                        console.log('[getNotAbsentProfessors] Middleware Error', error);
                    })
            })
            .catch(error => {
                console.log('[getNotAbsentProfessors] Middleware Error', error);
                // TODO: dispatch(getProfessorsAnyHourAvailableFail(errorMessage));
            });
    };
};


export const getAvailableSchoolClasses = (chosenDate) => {
    return dispatch => {
        dispatch(getAvailableSchoolClassesStart());
        axios.get('/v1/school_class/availability?date=' + chosenDate)
            .then(response => {
                const availableClasses = response.data
                    .sort((a, b) => {
                        var yearA = a.school_class.year;
                        var yearB = b.school_class.year;
                        if (yearA < yearB) return -1;
                        if (yearA > yearB) return 1;
                        var sectionA = a.school_class.section.toUpperCase();
                        var sectionB = b.school_class.section.toUpperCase();
                        return (sectionA < sectionB) ? -1 : (sectionA > sectionB) ? 1 : 0;
                    });
                dispatch(getAvailableSchoolClassesSuccess(availableClasses));
            })
            .catch(error => {
                let errorMessage = 'Qualcosa è andato storto. Per favore riprovare.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined && error.response.data.error !== undefined) {
                    errorMessage = error.response.data.error;
                }
                dispatch(getNotAbsentProfessorsFail(errorMessage));
            });
    };
};

export const saveAbsentClass = (data) => {
    return dispatch => {
        dispatch(saveAbsentClassStart());
        axios.post('/v1/school_class/absence', data)
            .then(response => {
                dispatch(saveAbsentClassSuccess());
            })
            .catch(error => {
                let errorMessage = 'Qualcosa è andato storto. Per favore riprovare.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined && error.response.data.error !== undefined) {
                    errorMessage = error.response.data.error;
                }
                dispatch(saveAbsentClassFail(errorMessage));
            })
    };
};