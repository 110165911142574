import {
    GET_SCHEDULE_START,
    GET_SCHEDULE_SUCCESS,
    GET_SCHEDULE_FAIL,
} from '../actions';

import axios from '../../axios-professors';

export const getScheduleStart = () => {
    return {
        type: GET_SCHEDULE_START
    }
};

export const getScheduleSuccess = (data) => {
    return {
        type: GET_SCHEDULE_SUCCESS,
        data: data,
    }
};

export const getScheduleFail = () => {
    return {
        type: GET_SCHEDULE_FAIL
    }
};

////// MIDDLEWARES (or Async Functions)
export const getSchedule = () => {
    return dispatch => {
        dispatch(getScheduleStart());
        axios.get('/v1/schedule')
            .then(response => {
                const responseData = response.data;
                    responseData.schedule.sort((a, b) => {
                        var textA = a.professor.toUpperCase();
                        var textB = b.professor.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                dispatch(getScheduleSuccess(responseData));
            })
            .catch(error => {
                console.log('[getSchedule] ERROR ', error);
                dispatch(getScheduleFail());
            })
    }
};