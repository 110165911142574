import {
    SUBMIT_FEEDBACK_START,
    SUBMIT_FEEDBACK_SUCCESS,
    SUBMIT_FEEDBACK_FAIL,
} from '../actions';

import { updateObject } from '../utility';


const initialState = {
    message: null,
    messageType: null,

    loading: false,
}

const submitFeedbackStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        message: null,
        messageType: null,
    });
}

const submitFeedbackSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        message: 'Il tuo feedback è stato inoltrato. Verrai contattato al più presto',
        messageType: 'success',
    });
}

const submitFeedbackFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        message: action.errorMessage,
        messageType: 'error',
    });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SUBMIT_FEEDBACK_START: return submitFeedbackStart(state, action);
        case SUBMIT_FEEDBACK_SUCCESS: return submitFeedbackSuccess(state, action);
        case SUBMIT_FEEDBACK_FAIL: return submitFeedbackFail(state, action);
        default: return state;
    }
}

export default reducer;