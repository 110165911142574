import {
    GET_SCHOOL_CLASS_ABSENCES_START,
    GET_SCHOOL_CLASS_ABSENCES_SUCCESS,
    GET_SCHOOL_CLASS_ABSENCES_FAIL,
    DELETE_SCHOOL_CLASS_ABSENCE_START,
    DELETE_SCHOOL_CLASS_ABSENCE_SUCCESS,
    DELETE_SCHOOL_CLASS_ABSENCE_FAIL,
    DELETE_ALL_SCHOOL_CLASS_ABSENCES_START,
    DELETE_ALL_SCHOOL_CLASS_ABSENCES_SUCCESS,
    DELETE_ALL_SCHOOL_CLASS_ABSENCES_FAIL,
    GET_PROFESSOR_PERMS_START,
    GET_PROFESSOR_PERMS_SUCCESS,
    GET_PROFESSOR_PERMS_FAIL,
    DELETE_PROFESSOR_PERM_START,
    DELETE_PROFESSOR_PERM_SUCCESS,
    DELETE_PROFESSOR_PERM_FAIL,
    DELETE_ALL_PROFESSOR_PERMS_START,
    DELETE_ALL_PROFESSOR_PERMS_SUCCESS,
    DELETE_ALL_PROFESSOR_PERMS_FAIL,
    GET_PROFESSOR_ABSENCES_START,
    GET_PROFESSOR_ABSENCES_SUCCESS,
    GET_PROFESSOR_ABSENCES_FAIL,
    DELETE_PROFESSOR_ABSENCE_START,
    DELETE_PROFESSOR_ABSENCE_SUCCESS,
    DELETE_PROFESSOR_ABSENCE_FAIL,
    DELETE_ALL_PROFESSOR_ABSENCES_START,
    DELETE_ALL_PROFESSOR_ABSENCES_SUCCESS,
    DELETE_ALL_PROFESSOR_ABSENCES_FAIL,
    GET_RESOLUTIONS_START,
    GET_RESOLUTIONS_SUCCESS,
    GET_RESOLUTIONS_FAIL,
    SHOW_SCHOOL_CLASS_ABSENCE_DELETE_MODAL,
    SHOW_PROFESSOR_ABSENCE_DELETE_MODAL,
    SHOW_PROFESSOR_PERM_DELETE_MODAL,
} from '../actions';

import { updateObject } from '../utility';

const initialState = {
    schoolClassAbsences: [],
    isSchoolClassAbsencesLoading: false,
    professorPerms: [],
    isProfessorPermsLoading: false,
    professorAbsences: [],
    isProfessorAbsencesLoading: false,
    resolutions: [],
    notifications: [],
    isResolutionsLoading: false,

    loading: false,
    showSchoolClassAbsenceDeleteModal: false,
    showProfessorAbsenceDeleteModal: false,
    showProfessorPermDeleteModal: false,

    message: null,
    messageType: null,
};

const getSchoolClassAbsencesStart = (state, action) => {
    return updateObject(state, { isSchoolClassAbsencesLoading: true });
};

const getSchoolClassAbsencesSuccess = (state, action) => {
    return updateObject(state, {
        isSchoolClassAbsencesLoading: false,
        schoolClassAbsences: [...action.schoolClassAbsences]
    });
};

const getSchoolClassAbsencesFail = (state, action) => {
    return updateObject(state, { isSchoolClassAbsencesLoading: false });
};

const deleteSchoolClassAbsenceStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const deleteSchoolClassAbsenceSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        schoolClassAbsences: [...action.updatedSchoolClassAbsences],
        showSchoolClassAbsenceDeleteModal: false,
        message: 'Assenze Classi cancellata con successo',
        messageType: 'success'
    });
};

const deleteSchoolClassAbsenceFail = (state, action) => {
    return updateObject(state, {
        message: action.errorMessage,
        messageType: 'error',
        loading: false,
    });
};

const deleteAllSchoolClassAbsencesStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        message: null,
        messageType: null,
    });
};

const deleteAllSchoolClassAbsencesSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        schoolClassAbsences: [...action.updatedSchoolClassAbsences],
        showDeleteAllSchoolClassAbsencesModal: false,
        message: 'Assenze cancellate con successo',
        messageType: 'success'
    });
};

const deleteAllSchoolClassAbsencesFail = (state, action) => {
    return updateObject(state, {
        message: action.errorMessage,
        messageType: 'error',
        loading: false,
    });
};


/////////////////////////////////////////////////////////////////////////////////////////////////
// PROFESSOR PERM
/////////////////////////////////////////////////////////////////////////////////////////////////
const getProfessorPermsStart = (state, action) => {
    return updateObject(state, { isProfessorPermsLoading: true });
};

const getProfessorPermsSuccess = (state, action) => {
    return updateObject(state, {
        isProfessorPermsLoading: false,
        professorPerms: action.professorPerms
    });
};

const getProfessorPermsFail = (state, action) => {
    return updateObject(state, { isProfessorPermsLoading: false });
};

const deleteProfessorPermStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        message: null,
        messageType: null,
    });
};

const deleteProfessorPermSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        professorPerms: [...action.updatedProfessorPerms],
        showProfessorPermDeleteModal: false,
        message: 'Permesso Docente cancellato con successo',
        messageType: 'success'
    });
};

const deleteProfessorPermFail = (state, action) => {
    return updateObject(state, {
        message: action.errorMessage,
        messageType: 'error',
        loading: false
    });
};

const deleteAllProfessorPermsStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        message: null,
        messageType: null,
    });
};

const deleteAllProfessorPermsSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        professorPerms: [...action.updatedProfessorPerms],
        showDeleteAllPermProfessorModal: false,
        message: 'Permessi cancellati con successo',
        messageType: 'success'
    });
};

const deleteAllProfessorPermsFail = (state, action) => {
    return updateObject(state, {
        message: action.errorMessage,
        messageType: 'error',
        loading: false
    });
};

/////////////////////////////////////////////////////////////////////////////////////////////////
// PROFESSOR ABSENCES  
/////////////////////////////////////////////////////////////////////////////////////////////////
export const getProfessorAbsencesStart = (state, action) => {
    return updateObject(state, { isProfessorAbsencesLoading: true });
};

export const getProfessorAbsencesSuccess = (state, action) => {
    return updateObject(state, {
        isProfessorAbsencesLoading: false,
        professorAbsences: [...action.professorAbsences]
    });
};

export const getProfessorAbsencesFail = (state, action) => {
    return updateObject(state, { isProfessorAbsencesLoading: false });
};

const deleteProfessorAbsenceStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const deleteProfessorAbsenceSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        professorAbsences: [...action.updatedProfessorAbsences],
        showProfessorAbsenceDeleteModal: false,
        message: 'Assenze Docenti cancellata con successo',
        messageType: 'success'
    });
};

const deleteProfessorAbsenceFail = (state, action) => {
    return updateObject(state, {
        message: action.errorMessage,
        messageType: 'error',
        loading: false
    });
};

const deleteAllProfessorAbsencesStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        message: null,
        messageType: null,
    });
};

const deleteAllProfessorAbsencesSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        professorAbsences: [...action.updatedProfessorAbsences],
        showDeleteAllProfessorAbsencesModal: false,
        message: 'Assenze cancellate con successo',
        messageType: 'success'
    });
};

const deleteAllProfessorAbsencesFail = (state, action) => {
    return updateObject(state, {
        message: action.errorMessage,
        messageType: 'error',
        loading: false
    });
};

/////////////////////////////////////////////////////////////////////////////////////////////////
// RESOLUTIONS  
/////////////////////////////////////////////////////////////////////////////////////////////////
export const getResolutionsStart = (state, action) => {
    return updateObject(state, { isResolutionsLoading: true });
};

export const getResolutionsSuccess = (state, action) => {
    return updateObject(state, {
        isResolutionsLoading: false,
        resolutions: [...action.resolutions],
        notifications: [...action.notifications],
    });
};

export const getResolutionsFail = (state, action) => {
    return updateObject(state, { isResolutionsLoading: false });
};


/////////////////////////////////////////////////////////////////////////////////////////////////
// SHOW MODALS
/////////////////////////////////////////////////////////////////////////////////////////////////
const showSchoolClassAbsenceDeleteModal = (state, action) => {
    return updateObject(state, { showSchoolClassAbsenceDeleteModal: action.show });
};

const showProfessorAbsenceDeleteModal = (state, action) => {
    return updateObject(state, { showProfessorAbsenceDeleteModal: action.show });
};

const showProfessorPermDeleteModal = (state, action) => {
    return updateObject(state, { showProfessorPermDeleteModal: action.show });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SCHOOL_CLASS_ABSENCES_START: return getSchoolClassAbsencesStart(state, action);
        case GET_SCHOOL_CLASS_ABSENCES_SUCCESS: return getSchoolClassAbsencesSuccess(state, action);
        case GET_SCHOOL_CLASS_ABSENCES_FAIL: return getSchoolClassAbsencesFail(state, action);
        case DELETE_SCHOOL_CLASS_ABSENCE_START: return deleteSchoolClassAbsenceStart(state, action);
        case DELETE_SCHOOL_CLASS_ABSENCE_SUCCESS: return deleteSchoolClassAbsenceSuccess(state, action);
        case DELETE_SCHOOL_CLASS_ABSENCE_FAIL: return deleteSchoolClassAbsenceFail(state, action);
        case DELETE_ALL_SCHOOL_CLASS_ABSENCES_START: return deleteAllSchoolClassAbsencesStart(state, action);
        case DELETE_ALL_SCHOOL_CLASS_ABSENCES_SUCCESS: return deleteAllSchoolClassAbsencesSuccess(state, action);
        case DELETE_ALL_SCHOOL_CLASS_ABSENCES_FAIL: return deleteAllSchoolClassAbsencesFail(state, action);
        case GET_PROFESSOR_PERMS_START: return getProfessorPermsStart(state, action);
        case GET_PROFESSOR_PERMS_SUCCESS: return getProfessorPermsSuccess(state, action);
        case GET_PROFESSOR_PERMS_FAIL: return getProfessorPermsFail(state, action);
        case DELETE_PROFESSOR_PERM_START: return deleteProfessorPermStart(state, action);
        case DELETE_PROFESSOR_PERM_SUCCESS: return deleteProfessorPermSuccess(state, action);
        case DELETE_PROFESSOR_PERM_FAIL: return deleteProfessorPermFail(state, action);
        case DELETE_ALL_PROFESSOR_PERMS_START: return deleteAllProfessorPermsStart(state, action);
        case DELETE_ALL_PROFESSOR_PERMS_SUCCESS: return deleteAllProfessorPermsSuccess(state, action);
        case DELETE_ALL_PROFESSOR_PERMS_FAIL: return deleteAllProfessorPermsFail(state, action);
        case GET_PROFESSOR_ABSENCES_START: return getProfessorAbsencesStart(state, action);
        case GET_PROFESSOR_ABSENCES_SUCCESS: return getProfessorAbsencesSuccess(state, action);
        case GET_PROFESSOR_ABSENCES_FAIL: return getProfessorAbsencesFail(state, action);
        case DELETE_PROFESSOR_ABSENCE_START: return deleteProfessorAbsenceStart(state, action);
        case DELETE_PROFESSOR_ABSENCE_SUCCESS: return deleteProfessorAbsenceSuccess(state, action);
        case DELETE_PROFESSOR_ABSENCE_FAIL: return deleteProfessorAbsenceFail(state, action);
        case DELETE_ALL_PROFESSOR_ABSENCES_START: return deleteAllProfessorAbsencesStart(state, action);
        case DELETE_ALL_PROFESSOR_ABSENCES_SUCCESS: return deleteAllProfessorAbsencesSuccess(state, action);
        case DELETE_ALL_PROFESSOR_ABSENCES_FAIL: return deleteAllProfessorAbsencesFail(state, action);
        case GET_RESOLUTIONS_START: return getResolutionsStart(state, action);
        case GET_RESOLUTIONS_SUCCESS: return getResolutionsSuccess(state, action);
        case GET_RESOLUTIONS_FAIL: return getResolutionsFail(state, action);
        case SHOW_SCHOOL_CLASS_ABSENCE_DELETE_MODAL: return showSchoolClassAbsenceDeleteModal(state, action);
        case SHOW_PROFESSOR_ABSENCE_DELETE_MODAL: return showProfessorAbsenceDeleteModal(state, action);
        case SHOW_PROFESSOR_PERM_DELETE_MODAL: return showProfessorPermDeleteModal(state, action);
        default: return state;
    }
};

export default reducer;