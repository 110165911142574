import {
    GET_PROFESSORS_ANY_HOUR_AVAILABLE_START,
    GET_PROFESSORS_ANY_HOUR_AVAILABLE_SUCCESS,
    GET_PROFESSORS_ANY_HOUR_AVAILABLE_FAIL,
    SAVE_ABSENT_PROFESSORS_START,
    SAVE_ABSENT_PROFESSORS_SUCCESS,
    SAVE_ABSENT_PROFESSORS_FAIL,
    SET_SHOW_ABSENT_PROFESSOR_MODAL,
} from '../actions';

import axios from '../../axios-professors';

/////////////////////////////////////////////////////////////////////////////
// PROFESSORS AVAILABILITY
/////////////////////////////////////////////////////////////////////////////
export const getProfessorsAnyHourAvailableStart = () => {
    return {
        type: GET_PROFESSORS_ANY_HOUR_AVAILABLE_START
    }
};

export const getProfessorsAnyHourAvailableSuccess = (professors, schoolClassSettings) => {
    return {
        type: GET_PROFESSORS_ANY_HOUR_AVAILABLE_SUCCESS,
        professors: professors,
        schoolClassSettings: schoolClassSettings,
    }
};

export const getProfessorsAnyHourAvailableFail = (errorMessage) => {
    return {
        type: GET_PROFESSORS_ANY_HOUR_AVAILABLE_FAIL,
        errorMessage: errorMessage
    }
};

/////////////////////////////////////////////////////////////////////////////
// PROFESSOR ABSENCE
/////////////////////////////////////////////////////////////////////////////
export const saveAbsentProfessorsStart = () => {
    return {
        type: SAVE_ABSENT_PROFESSORS_START
    }
};

export const saveAbsentProfessorsSuccess = () => {
    return {
        type: SAVE_ABSENT_PROFESSORS_SUCCESS,
    }
};

export const saveAbsentProfessorsFail = (errorMessage) => {
    return {
        type: SAVE_ABSENT_PROFESSORS_FAIL,
        errorMessage: errorMessage
    }
};

/////////////////////////////////////////////////////////////////////////////
// OTHER
/////////////////////////////////////////////////////////////////////////////
export const setShowAbsentProfessorModal = (show) => {
    return {
        type: SET_SHOW_ABSENT_PROFESSOR_MODAL,
        show: show
    }
};

////// MIDDLEWARES (or Async Functions)
export const getAllDayAvailableProfessors = (chosenDate) => {
    return dispatch => {
        dispatch(getProfessorsAnyHourAvailableStart());
        axios.get('/v1/professor/availability?date=' + chosenDate)
            .then(response => {
                const professors = response.data
                    .sort((a, b) => {
                        var textA = a.professor.fiscal_code.toUpperCase();
                        var textB = b.professor.fiscal_code.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    }); 
                    axios.get('/v1/school/settings')
                    .then(response => { 
                        const schoolClassSettings = response.data;
                        dispatch(getProfessorsAnyHourAvailableSuccess(professors, schoolClassSettings));
                    })
                    .catch(error => {
                        console.log('[getNotAbsentProfessors] Middleware Error', error);
                    })
            })
            .catch(error => {
                console.log('[initAllDayAvailableProfessors] Middleware Error', error);
                // TODO: dispatch(getProfessorsAnyHourAvailableFail(errorMessage));
            });
    };
};

export const saveAbsentProfessors = (data) => {
    return dispatch => {
        dispatch(saveAbsentProfessorsStart())
        axios.post('/v1/professor/absence', data)
            .then(response => {
                dispatch(saveAbsentProfessorsSuccess());
            })
            .catch(error => {
                let errorMessage = 'Qualcosa è andato storto. Per favore riprovare.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined && error.response.data.error !== undefined) {
                    errorMessage = error.response.data.error;
                }
                dispatch(saveAbsentProfessorsFail(errorMessage));
            })
    };
};