import {
    AUTH_START,
    AUTH_SUCCESS,
    AUTH_FAIL,
    AUTH_LOGOUT_START,
    AUTH_LOGOUT_SUCCESS,
    AUTH_LOGOUT_FAIL,
    AUTH_LOGOUT,
    UPDATE_TODOS,
    REFRESH_JWT_TOKEN_START,
    REFRESH_JWT_TOKEN_END,
    SIGNUP_START,
    SIGNUP_SUCCESS,
    SIGNUP_FAIL,
    RESET_PASSWORD_START,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL,
    FORGOT_PASSWORD_START,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAIL,
    ADMIN_SIGNUP_START,
    ADMIN_SIGNUP_SUCCESS,
    ADMIN_SIGNUP_FAIL,
    ASK_NEW_LINK_START,
    ASK_NEW_LINK_SUCCESS,
    ASK_NEW_LINK_FAIL,
} from '../actions';

import { updateObject } from '../utility';

const initialState = {
    token: null,
    userId: null,
    todos: null,
    isStarting: true,

    message: null,
    messageType: null,
    authLoading: false,
    signupLoading: false,
    logoutLoading: false,
    resetPasswordLoading: false,
    adminSignupLoading: false,
    askNewLinkLoading: false,

    firstAuthentication: true,
}

const authStart = (state, action) => {
    return updateObject(state, {
        authLoading: true,
        message: null,
        messageType: null,
    });
}

const authSuccess = (state, action) => {
    let message = null;
    let messageType = null;
    let firstAuthentication = false;

    let todos = state.todos;
    if (action.todos !== undefined) {
        todos = action.todos;
    }

    if (state.firstAuthentication) {
        message = 'Benvenuto :)';
        messageType = 'success';
    }

    return updateObject(state, {
        token: action.token,
        userId: action.userId,
        todos: todos,

        authLoading: false,
        message: message,
        messageType: messageType,
        firstAuthentication: firstAuthentication,
    });
}

const authFail = (state, action) => {
    return updateObject(state, {
        authLoading: false,
        message: action.errorMessage,
        messageType: 'error',
    });
}

const authLogoutStart = (state, action) => {
    return updateObject(state, {
        logoutLoading: true,
        message: null,
        messageType: null,
    });
}

const authLogoutSuccess = (state, action) => {
    return updateObject(state, {
        token: null,
        userId: null,
        logoutLoading: false,
    });
}

const authLogoutFail = (state, action) => {
    return updateObject(state, {
        logoutLoading: false,
        message: action.errorMessage,
        messageType: 'error',
    });
}

const authLogout = (state, action) => {
    return updateObject(state, {
        token: null,
        userId: null,
    });
}

const updateTodos = (state, action) => {
    return updateObject(state, {
        todos: action.todos,
    })
}

const refreshJWTTokenStart = (state, action) => {
    return updateObject(state, {
        refreshTokenLogin: true,
        message: null,
        messageType: null,
    })
}

const refreshJWTTokenEnd = (state, action) => {
    return updateObject(state, {
        refreshTokenLogin: false,
        isStarting: false,
    })
}

const signupStart = (state, action) => {
    return updateObject(state, {
        signupLoading: true,
        message: null,
        messageType: null,
    });
}

const signupSuccess = (state, action) => {
    return updateObject(state, {
        signupLoading: false,
        message: 'Grazie per l\'interesse. Verrai contattato al più presto!',
        messageType: 'success',
    });
}

const signupFail = (state, action) => {
    return updateObject(state, {
        signupLoading: false,
        message: action.errorMessage,
        messageType: 'error',
    });
}

const resetPasswordStart = (state, action) => {
    return updateObject(state, {
        resetPasswordLoading: true,
        message: null,
        messageType: null,
    });
}

const resetPasswordSuccess = (state, action) => {
    return updateObject(state, {
        resetPasswordLoading: false,
        message: 'Password resettata con successo',
        messageType: 'success',
    });
}

const resetPasswordFail = (state, action) => {
    return updateObject(state, {
        resetPasswordLoading: false,
        message: action.errorMessage,
        messageType: 'error',
    });
}

const forgotPasswordStart = (state, action) => {
    return updateObject(state, {
        resetPasswordLoading: true,
        message: null,
        messageType: null,
    });
}

const forgotPasswordSuccess = (state, action) => {
    return updateObject(state, {
        resetPasswordLoading: false,
        message: 'Le abbiamo inviato una email per resettare la password',
        messageType: 'success',
    });
}

const forgotPasswordFail = (state, action) => {
    return updateObject(state, {
        resetPasswordLoading: false,
        message: action.errorMessage,
        messageType: 'error',
    });
}

const adminSignupStart = (state, action) => {
    return updateObject(state, {
        adminSignupLoading: true,
        message: null,
        messageType: null,
    });
}

const adminSignupSuccess = (state, action) => {
    return updateObject(state, {
        adminSignupLoading: false,
        message: 'Le abbiamo inviato una email per resettare la password',
        messageType: 'success',
    });
}

const adminSignupFail = (state, action) => {
    return updateObject(state, {
        adminSignupLoading: false,
        message: action.errorMessage,
        messageType: 'error',
    });
}

const askNewLinkStart = (state, action) => {
    return updateObject(state, {
        askNewLinkLoading: true,
        message: null,
        messageType: null,
    });
}

const askNewLinkSuccess = (state, action) => {
    return updateObject(state, {
        askNewLinkLoading: false,
        message: 'Controlla la tua email e clicca sul nuovo link ricevuto.',
        messageType: 'success',
    });
}

const askNewLinkFail = (state, action) => {
    return updateObject(state, {
        askNewLinkLoading: false,
        message: action.errorMessage,
        messageType: 'error',
    });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_START: return authStart(state, action);
        case AUTH_FAIL: return authFail(state, action);
        case AUTH_SUCCESS: return authSuccess(state, action);
        case AUTH_LOGOUT_START: return authLogoutStart(state, action);
        case AUTH_LOGOUT_FAIL: return authLogoutFail(state, action);
        case AUTH_LOGOUT_SUCCESS: return authLogoutSuccess(state, action);
        case AUTH_LOGOUT: return authLogout(state, action);
        case SIGNUP_START: return signupStart(state, action);
        case SIGNUP_SUCCESS: return signupSuccess(state, action);
        case SIGNUP_FAIL: return signupFail(state, action);
        case RESET_PASSWORD_START: return resetPasswordStart(state, action);
        case RESET_PASSWORD_SUCCESS: return resetPasswordSuccess(state, action);
        case RESET_PASSWORD_FAIL: return resetPasswordFail(state, action);
        case FORGOT_PASSWORD_START: return forgotPasswordStart(state, action);
        case FORGOT_PASSWORD_SUCCESS: return forgotPasswordSuccess(state, action);
        case FORGOT_PASSWORD_FAIL: return forgotPasswordFail(state, action);
        case ADMIN_SIGNUP_START: return adminSignupStart(state, action);
        case ADMIN_SIGNUP_SUCCESS: return adminSignupSuccess(state, action);
        case ADMIN_SIGNUP_FAIL: return adminSignupFail(state, action);
        case ASK_NEW_LINK_START: return askNewLinkStart(state, action);
        case ASK_NEW_LINK_SUCCESS: return askNewLinkSuccess(state, action);
        case ASK_NEW_LINK_FAIL: return askNewLinkFail(state, action);
        case UPDATE_TODOS: return updateTodos(state, action);
        case REFRESH_JWT_TOKEN_START: return refreshJWTTokenStart(state, action);
        case REFRESH_JWT_TOKEN_END: return refreshJWTTokenEnd(state, action);
        default: return state;
    }
}

export default reducer;