import {
    GET_SCHOOL_SETTINGS_START,
    GET_SCHOOL_SETTINGS_SUCCESS,
    GET_SCHOOL_SETTINGS_FAIL,
    UPDATE_SCHOOL_SETTINGS_START,
    UPDATE_SCHOOL_SETTINGS_SUCCESS,
    UPDATE_SCHOOL_SETTINGS_FAIL,
    CREATE_NEW_ACADEMIC_YEAR_START,
    CREATE_NEW_ACADEMIC_YEAR_SUCCESS,
    CREATE_NEW_ACADEMIC_YEAR_FAIL,
    CREATE_NEW_SUBJECT_START,
    CREATE_NEW_SUBJECT_SUCCESS,
    CREATE_NEW_SUBJECT_FAIL,
    SET_SHOW_CREATE_NEW_ACADEMIC_YEAR_MODAL,
    SET_SHOW_SUBJECT_MODAL,
} from '../actions';

import axios from '../../axios-professors';

export const getSchoolSettingsStart = () => {
    return {
        type: GET_SCHOOL_SETTINGS_START
    }
};

export const getSchoolSettingsSuccess = (schoolSettings) => {
    return {
        type: GET_SCHOOL_SETTINGS_SUCCESS,
        schoolSettings: schoolSettings,
    }
};

export const getSchoolSettingsFail = () => {
    return {
        type: GET_SCHOOL_SETTINGS_FAIL
    }
};

export const updateSchoolSettingsStart = () => {
    return {
        type: UPDATE_SCHOOL_SETTINGS_START
    }
};

export const updateSchoolSettingsSuccess = (schoolSettings) => {
    return {
        type: UPDATE_SCHOOL_SETTINGS_SUCCESS,
        schoolSettings: schoolSettings,
    }
};

export const updateSchoolSettingsFail = (error) => {
    return {
        type: UPDATE_SCHOOL_SETTINGS_FAIL,
        error: error,
    }
};

export const createNewAcademicYearStart = () => {
    return {
        type: CREATE_NEW_ACADEMIC_YEAR_START
    }
};

export const createNewAcademicYearSuccess = () => {
    return {
        type: CREATE_NEW_ACADEMIC_YEAR_SUCCESS
    }
};

export const createNewAcademicYearFail = (errorMessage) => {
    return {
        type: CREATE_NEW_ACADEMIC_YEAR_FAIL,
        errorMessage: errorMessage
    }
};

export const createNewSubjectStart = () => {
    return {
        type: CREATE_NEW_SUBJECT_START
    }
};

export const createNewSubjectSuccess = (schoolSettings) => {
    return {
        type: CREATE_NEW_SUBJECT_SUCCESS,
        schoolSettings: schoolSettings,
    }
};

export const createNewSubjectFail = (errorMessage) => {
    return {
        type: CREATE_NEW_SUBJECT_FAIL,
        errorMessage: errorMessage
    }
};

export const setShowCreateNewAcademicYearModal = (show) => {
    return {
        type: SET_SHOW_CREATE_NEW_ACADEMIC_YEAR_MODAL,
        show: show
    }
};

export const setShowSubjectModal = (show) => {
    return {
        type: SET_SHOW_SUBJECT_MODAL,
        show: show
    }
};

////// MIDDLEWARES (or Async Functions)
export const getSchoolSettings = () => {
    return dispatch => {
        dispatch(getSchoolSettingsStart());
        axios.get('/v1/school/settings')
            .then(response => {
                dispatch(getSchoolSettingsSuccess(response.data));
            })
            .catch(error => {
                console.log('[getSchoolSettings] ERROR ', error);
                dispatch(getSchoolSettingsFail());
            })
    }
};

export const updateSchoolSettings = (data) => {
    return dispatch => {
        dispatch(updateSchoolSettingsStart());
        axios.put('/v1/school/settings', data)
            .then(response => {
                dispatch(updateSchoolSettingsSuccess(response.data));
            })
            .catch(error => {
                let errorMessage = 'Qualcosa è andato storto. Per favore riprovare.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined && error.response.data.error !== undefined) {
                    errorMessage = error.response.data.error;
                }
                dispatch(updateSchoolSettingsFail(errorMessage));
            })
    }
};

export const createNewAcademicYear = (history) => {
    return dispatch => {
        dispatch(createNewAcademicYearStart());
        axios.post('/v1/school_to_academic_year')
            .then(response => {
                dispatch(createNewAcademicYearSuccess());
                history.push('/');
            })
            .catch(error => {
                let errorMessage = 'Qualcosa è andato storto. Per favore riprovare.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined && error.response.data.error !== undefined) {
                    errorMessage = error.response.data.error;
                }
                dispatch(createNewAcademicYearFail(errorMessage));
            })
    }
};

export const createNewSubject = (data) => {
    return dispatch => {
        dispatch(createNewSubjectStart());
        axios.post('/v1/subject', data)
            .then(response => {
                // Return all school settings...
                dispatch(createNewSubjectSuccess(response.data));
            })
            .catch(error => {
                let errorMessage = 'Qualcosa è andato storto. Per favore riprovare.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined && error.response.data.error !== undefined) {
                    errorMessage = error.response.data.error;
                }
                dispatch(createNewSubjectFail(errorMessage));
            })
    }
};