import {
    COMPUTE_REPLACEMENTS_START,
    COMPUTE_REPLACEMENTS_SUCCESS,
    COMPUTE_REPLACEMENTS_FAIL,
    SET_SHOW_REPLACEMENT_CHECKOUT_MODAL,
} from '../actions';

import axios from '../../axios-professors';

export const computeReplacementsStart = () => {
    return {
        type: COMPUTE_REPLACEMENTS_START
    }
};

export const computeReplacementsSuccess = () => {
    return {
        type: COMPUTE_REPLACEMENTS_SUCCESS
    }
};

export const computeReplacementsFail = (errorMessage, needsForce) => {
    return {
        type: COMPUTE_REPLACEMENTS_FAIL,
        errorMessage: errorMessage,
        needsForce: needsForce,
    }
};

export const setShowReplacementCheckoutModal = (show) => {
    return {
        type: SET_SHOW_REPLACEMENT_CHECKOUT_MODAL,
        show: show
    }
};

////// MIDDLEWARES (or Async Functions)
export const computeReplacements = (data) => {
    return dispatch => {
        dispatch(computeReplacementsStart());
        axios({
            method: 'post',
            url: '/v1/replacement',
            data: data,
            responseType: 'blob'
        })
            .then(response => {
                var file_prefix = 'variazioni_orario_';
                if (data['mode'] === 'MODE_REPLACEMENT_PROFESSORS') {
                    file_prefix = "sostituzioni_";
                } else if (data['mode'] === 'MODE_PROFESSORS') {
                    file_prefix = "docenti_";
                }
                const file_name = file_prefix + data['date'] + '.pdf'
                const fileBlob = new Blob([response.data]);
                const url = window.URL.createObjectURL(fileBlob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', file_name);
                document.body.appendChild(link);
                link.click();
                dispatch(computeReplacementsSuccess());

                axios.get(`/v1/replacement/s3/sign?file_name=${file_name}&replacement_date=${data['date']}&replacement_type=${data['mode']}`)
                    .then(response => {
                        axios.put(response.data.presigned_url, fileBlob, {
                            // upload progress is never called
                            // onUploadProgress: (e) => { return console.log('Never called', e); },
                            // cancel token, doesn't affect result
                            // cancelToken: cancelToken.token,
                            // our defaults set this as true, but changing it doesn't affect result
                            withCredentials: false,
                            // Various combinations of fake types, no types, multipart types don't affect result
                            headers: { 'Content-Type': 'application/pdf' },
                        })
                            .then(response => {
                                // console.log("AWS RESPo!", response);
                            })
                            .catch(error => {
                                // console.log("AWSSS ERRROR !", error);
                            })
                    })
                    .catch(error => {
                        // console.log("DANG :(!", error);
                    })

            })
            .catch(error => {
                let errorMessage = 'Qualcosa è andato storto. Per favore riprovare.';
                let needsForce = false;
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined && error.response.data.error !== undefined) {
                    if ("needs_force" in error.response.data) {
                        needsForce = error.response.data.needs_force;
                    }
                    errorMessage = error.response.data.error;
                }
                dispatch(computeReplacementsFail(errorMessage, needsForce));
            })
    };
};