import {
    GET_SCHOOL_CLASS_ABSENCES_START,
    GET_SCHOOL_CLASS_ABSENCES_SUCCESS,
    GET_SCHOOL_CLASS_ABSENCES_FAIL,
    DELETE_SCHOOL_CLASS_ABSENCE_START,
    DELETE_SCHOOL_CLASS_ABSENCE_SUCCESS,
    DELETE_SCHOOL_CLASS_ABSENCE_FAIL,
    DELETE_ALL_SCHOOL_CLASS_ABSENCES_START,
    DELETE_ALL_SCHOOL_CLASS_ABSENCES_SUCCESS,
    DELETE_ALL_SCHOOL_CLASS_ABSENCES_FAIL,
    GET_PROFESSOR_PERMS_START,
    GET_PROFESSOR_PERMS_SUCCESS,
    GET_PROFESSOR_PERMS_FAIL,
    DELETE_PROFESSOR_PERM_START,
    DELETE_PROFESSOR_PERM_SUCCESS,
    DELETE_PROFESSOR_PERM_FAIL,
    DELETE_ALL_PROFESSOR_ABSENCES_START,
    DELETE_ALL_PROFESSOR_ABSENCES_SUCCESS,
    DELETE_ALL_PROFESSOR_ABSENCES_FAIL,
    GET_PROFESSOR_ABSENCES_START,
    GET_PROFESSOR_ABSENCES_SUCCESS,
    GET_PROFESSOR_ABSENCES_FAIL,
    DELETE_PROFESSOR_ABSENCE_START,
    DELETE_PROFESSOR_ABSENCE_SUCCESS,
    DELETE_PROFESSOR_ABSENCE_FAIL,
    DELETE_ALL_PROFESSOR_PERMS_START,
    DELETE_ALL_PROFESSOR_PERMS_SUCCESS,
    DELETE_ALL_PROFESSOR_PERMS_FAIL,
    GET_RESOLUTIONS_START,
    GET_RESOLUTIONS_SUCCESS,
    GET_RESOLUTIONS_FAIL,
    SHOW_SCHOOL_CLASS_ABSENCE_DELETE_MODAL,
    SHOW_PROFESSOR_ABSENCE_DELETE_MODAL,
    SHOW_PROFESSOR_PERM_DELETE_MODAL,
} from '../actions';

import axios from '../../axios-professors';

/////////////////////////////////////////////////////////////////////////////
// SCHOOL CLASS ABSENCE
/////////////////////////////////////////////////////////////////////////////
export const getSchoolClassAbsencesStart = () => {
    return {
        type: GET_SCHOOL_CLASS_ABSENCES_START
    }
};

export const getSchoolClassAbsencesSuccess = (data) => {
    return {
        type: GET_SCHOOL_CLASS_ABSENCES_SUCCESS,
        schoolClassAbsences: data
    }
};

export const getSchoolClassAbsencesFail = () => {
    return {
        type: GET_SCHOOL_CLASS_ABSENCES_FAIL
    }
};

export const deleteSchoolClassAbsenceStart = () => {
    return {
        type: DELETE_SCHOOL_CLASS_ABSENCE_START
    }
};

export const deleteSchoolClassAbsenceSuccess = (data) => {
    return {
        type: DELETE_SCHOOL_CLASS_ABSENCE_SUCCESS,
        updatedSchoolClassAbsences: data,
    }
};

export const deleteSchoolClassAbsenceFail = (errorMessage) => {
    return {
        errorMessage: errorMessage,
        type: DELETE_SCHOOL_CLASS_ABSENCE_FAIL
    }
};

export const deleteAllSchoolClassAbsencesStart = () => {
    return {
        type: DELETE_ALL_SCHOOL_CLASS_ABSENCES_START
    }
};

export const deleteAllSchoolClassAbsencesSuccess = (data) => {
    return {
        type: DELETE_ALL_SCHOOL_CLASS_ABSENCES_SUCCESS,
        updatedSchoolClassAbsences: data,
    }
};

export const deleteAllSchoolClassAbsencesFail = (errorMessage) => {
    return {
        errorMessage: errorMessage,
        type: DELETE_ALL_SCHOOL_CLASS_ABSENCES_FAIL
    }
};
/////////////////////////////////////////////////////////////////////////////
// PROFESSOR PERM
/////////////////////////////////////////////////////////////////////////////
export const getProfessorPermsStart = () => {
    return {
        type: GET_PROFESSOR_PERMS_START
    }
};

export const getProfessorPermsSuccess = (data) => {
    return {
        type: GET_PROFESSOR_PERMS_SUCCESS,
        professorPerms: data,
    }
};

export const getProfessorPermsFail = () => {
    return {
        type: GET_PROFESSOR_PERMS_FAIL
    }
};


export const deleteProfessorPermStart = () => {
    return {
        type: DELETE_PROFESSOR_PERM_START
    }
};

export const deleteProfessorPermSuccess = (data) => {
    return {
        type: DELETE_PROFESSOR_PERM_SUCCESS,
        updatedProfessorPerms: data,
    }
};

export const deleteProfessorPermFail = (errorMessage) => {
    return {
        errorMessage: errorMessage,
        type: DELETE_PROFESSOR_PERM_FAIL
    }
};

export const deleteAllProfessorPermsStart = () => {
    return {
        type: DELETE_ALL_PROFESSOR_PERMS_START
    }
};

export const deleteAllProfessorPermsSuccess = (data) => {
    return {
        type: DELETE_ALL_PROFESSOR_PERMS_SUCCESS,
        updatedProfessorPerms: data,
    }
};

export const deleteAllProfessorPermsFail = (errorMessage) => {
    return {
        errorMessage: errorMessage,
        type: DELETE_ALL_PROFESSOR_PERMS_FAIL
    }
};

/////////////////////////////////////////////////////////////////////////////////////////////////
// PROFESSOR ABSENCES  
/////////////////////////////////////////////////////////////////////////////////////////////////
export const getProfessorAbsencesStart = () => {
    return {
        type: GET_PROFESSOR_ABSENCES_START
    }
};

export const getProfessorAbsencesSuccess = (data) => {
    return {
        type: GET_PROFESSOR_ABSENCES_SUCCESS,
        professorAbsences: data,
    }
};

export const getProfessorAbsencesFail = () => {
    return {
        type: GET_PROFESSOR_ABSENCES_FAIL
    }
};

export const deleteProfessorAbsenceStart = () => {
    return {
        type: DELETE_PROFESSOR_ABSENCE_START
    }
};

export const deleteProfessorAbsenceSuccess = (data) => {
    return {
        type: DELETE_PROFESSOR_ABSENCE_SUCCESS,
        updatedProfessorAbsences: data,
    }
};

export const deleteProfessorAbsenceFail = (errorMessage) => {
    return {
        errorMessage: errorMessage,
        type: DELETE_PROFESSOR_ABSENCE_FAIL
    }
};

export const deleteAllProfessorAbsencesStart = () => {
    return {
        type: DELETE_ALL_PROFESSOR_ABSENCES_START
    }
};

export const deleteAllProfessorAbsencesSuccess = (data) => {
    return {
        type: DELETE_ALL_PROFESSOR_ABSENCES_SUCCESS,
        updatedProfessorAbsences: data,
    }
};

export const deleteAllProfessorAbsencesFail = (errorMessage) => {
    return {
        errorMessage: errorMessage,
        type: DELETE_ALL_PROFESSOR_ABSENCES_FAIL
    }
};

/////////////////////////////////////////////////////////////////////////////////////////////////
// SHOW MODALS
/////////////////////////////////////////////////////////////////////////////////////////////////
export const showSchoolClassAbsenceDeleteModal = (show) => {
    return {
        type: SHOW_SCHOOL_CLASS_ABSENCE_DELETE_MODAL,
        show: show
    }
};

export const showProfessorAbsenceDeleteModal = (show) => {
    return {
        type: SHOW_PROFESSOR_ABSENCE_DELETE_MODAL,
        show: show
    }
};

export const showProfessorPermDeleteModal = (show) => {
    return {
        type: SHOW_PROFESSOR_PERM_DELETE_MODAL,
        show: show
    }
};

/////////////////////////////////////////////////////////////////////////////////////////////////
// RESOLUTIONS  
/////////////////////////////////////////////////////////////////////////////////////////////////
export const getResolutionsStart = () => {
    return {
        type: GET_RESOLUTIONS_START
    }
};

export const getResolutionsSuccess = (resolutions, notifications) => {
    return {
        type: GET_RESOLUTIONS_SUCCESS,
        resolutions: resolutions,
        notifications: notifications,
    }
};

export const getResolutionsFail = () => {
    return {
        type: GET_RESOLUTIONS_FAIL
    }
};

////// MIDDLEWARES (or Async Functions)
export const getSchoolClassAbsences = (chosenDate) => {
    return dispatch => {
        dispatch(getSchoolClassAbsencesStart());
        axios.get('/v1/school_class/absence?date=' + chosenDate)
            .then(response => {
                const absentClasses = response.data
                    .sort((a, b) => {
                        var yearA = a.school_class.year;
                        var yearB = b.school_class.year;
                        if (yearA < yearB) return -1;
                        if (yearA > yearB) return 1;
                        var sectionA = a.school_class.section.toUpperCase();
                        var sectionB = b.school_class.section.toUpperCase();
                        return (sectionA < sectionB) ? -1 : (sectionA > sectionB) ? 1 : 0;
                    });
                dispatch(getSchoolClassAbsencesSuccess(absentClasses));
            })
            .catch(error => {
                console.log('[Middleware: getSchoolClassAbsences] error', error);
                dispatch(getSchoolClassAbsencesFail());
            });
    }
};

export const deleteSchoolClassAbsence = (data) => {
    return dispatch => {
        dispatch(deleteSchoolClassAbsenceStart());

        axios.post('/v1/school_class/absence/delete', data)
            .then(response => {
                const absentClasses = response.data
                    .sort((a, b) => {
                        var yearA = a.school_class.year;
                        var yearB = b.school_class.year;
                        if (yearA < yearB) return -1;
                        if (yearA > yearB) return 1;
                        var sectionA = a.school_class.section.toUpperCase();
                        var sectionB = b.school_class.section.toUpperCase();
                        return (sectionA < sectionB) ? -1 : (sectionA > sectionB) ? 1 : 0;
                    });
                dispatch(deleteSchoolClassAbsenceSuccess(absentClasses));
            })
            .catch(error => {
                let errorMessage = 'Qualcosa è andato storto. Per favore riprovare.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined && error.response.data.error !== undefined) {
                    errorMessage = error.response.data.error;
                }
                dispatch(deleteSchoolClassAbsenceFail(errorMessage));
            })
    };
};

export const deleteAllSchoolClassAbsences = (data) => {
    return dispatch => {
        dispatch(deleteAllSchoolClassAbsencesStart());

        axios.delete('/v1/school_class/absence/all', { params: data })
            .then(response => {
                const absentClasses = response.data
                    .sort((a, b) => {
                        var yearA = a.school_class.year;
                        var yearB = b.school_class.year;
                        if (yearA < yearB) return -1;
                        if (yearA > yearB) return 1;
                        var sectionA = a.school_class.section.toUpperCase();
                        var sectionB = b.school_class.section.toUpperCase();
                        return (sectionA < sectionB) ? -1 : (sectionA > sectionB) ? 1 : 0;
                    });
                dispatch(deleteAllSchoolClassAbsencesSuccess(absentClasses));
            })
            .catch(error => {
                let errorMessage = 'Qualcosa è andato storto. Per favore riprovare.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined && error.response.data.error !== undefined) {
                    errorMessage = error.response.data.error;
                }
                dispatch(deleteAllSchoolClassAbsencesFail(errorMessage));
            })
    };
};

export const getProfessorPerms = (chosenDate) => {
    return dispatch => {
        dispatch(getProfessorPermsStart());
        axios.get('/v1/professor/absence?date=' + chosenDate + '&absence_type=PERMESSO')
            .then(response => {
                const perm_tickets = response.data.reduce(function (permTicketist, obj) {
                    const profPermTickets = obj.absences.map(absence => {
                        var rObj = {};
                        rObj['professor'] = obj.professor;
                        rObj['absence'] = absence;
                        return rObj;
                    });
                    return [...permTicketist, ...profPermTickets];
                }, []);
                const professors = perm_tickets
                    .sort((a, b) => {
                        var textA = a.professor.fiscal_code.toUpperCase();
                        var textB = b.professor.fiscal_code.toUpperCase();
                        if (textA < textB) return -1;
                        if (textA > textB) return 1;
                        var absenceA = a.absence.day_hour;
                        var absenceB = b.absence.day_hour;
                        return absenceA - absenceB;
                    });
                dispatch(getProfessorPermsSuccess(professors));
            })
            .catch(error => {
                console.log('[Middleware: getProfessorPerms] error', error);
                dispatch(getProfessorPermsFail());
            });
    }
};

export const deleteProfessorPerm = (data) => {
    return dispatch => {
        dispatch(deleteProfessorPermStart());

        axios.post('/v1/professor/absence/id', data)
            .then(response => {
                const perm_tickets = response.data.reduce(function (permTicketist, obj) {
                    const profPermTickets = obj.absences.map(absence => {
                        var rObj = {};
                        rObj['professor'] = obj.professor;
                        rObj['absence'] = absence;
                        return rObj;
                    });
                    return [...permTicketist, ...profPermTickets];
                }, []);
                const professors = perm_tickets
                    .sort((a, b) => {
                        var textA = a.professor.fiscal_code.toUpperCase();
                        var textB = b.professor.fiscal_code.toUpperCase();
                        if (textA < textB) return -1;
                        if (textA > textB) return 1;
                        var absenceA = a.absence.day_hour;
                        var absenceB = b.absence.day_hour;
                        return absenceA - absenceB;
                    });
                dispatch(deleteProfessorPermSuccess(professors));
            })
            .catch(error => {
                let errorMessage = 'Qualcosa è andato storto. Per favore riprovare.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined && error.response.data.error !== undefined) {
                    errorMessage = error.response.data.error;
                }
                dispatch(deleteProfessorPermFail(errorMessage));
            })
    };
};

export const deleteAllProfessorPerms = (data) => {
    return dispatch => {
        dispatch(deleteAllProfessorPermsStart());
        let deleteParams = data;
        deleteParams['absence_type'] = 'PERMESSO';
        axios.delete('/v1/professor/absence/all', { params: deleteParams })
            .then(response => {
                const absences = response.data
                    .sort((a, b) => {
                        var textA = a.professor.fiscal_code.toUpperCase();
                        var textB = b.professor.fiscal_code.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                dispatch(deleteAllProfessorPermsSuccess(absences));
            })
            .catch(error => {
                let errorMessage = 'Qualcosa è andato storto. Per favore riprovare.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined && error.response.data.error !== undefined) {
                    errorMessage = error.response.data.error;
                }
                dispatch(deleteAllProfessorPermsFail(errorMessage));
            })
    };
};

export const getProfessorAbsences = (chosenDate) => {
    return dispatch => {
        dispatch(getProfessorAbsencesStart());
        axios.get('/v1/professor/absence?date=' + chosenDate + '&absence_type=ABSENCE')
            .then(response => {
                const absences = response.data
                    .sort((a, b) => {
                        var textA = a.professor.fiscal_code.toUpperCase();
                        var textB = b.professor.fiscal_code.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                dispatch(getProfessorAbsencesSuccess(absences))
            })
            .catch(error => {
                console.log('[Middleware: getProfessorAbsencesStart] error', error);
                dispatch(getProfessorAbsencesFail());
            });
    }
};

export const deleteProfessorAbsence = (data) => {
    return dispatch => {
        dispatch(deleteProfessorAbsenceStart());
        axios.post('/v1/professor/absence/id', data)
            .then(response => {
                const absences = response.data
                    .sort((a, b) => {
                        var textA = a.professor.fiscal_code.toUpperCase();
                        var textB = b.professor.fiscal_code.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                dispatch(deleteProfessorAbsenceSuccess(absences));
            })
            .catch(error => {
                let errorMessage = 'Qualcosa è andato storto. Per favore riprovare.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined && error.response.data.error !== undefined) {
                    errorMessage = error.response.data.error;
                }
                dispatch(deleteProfessorAbsenceFail(errorMessage));
            })
    };
};

export const deleteAllProfessorAbsences = (data) => {
    return dispatch => {
        dispatch(deleteAllProfessorAbsencesStart());
        let deleteParams = data;
        deleteParams['absence_type'] = 'ABSENCE';
        axios.delete('/v1/professor/absence/all', { params: deleteParams })
            .then(response => {
                const absences = response.data
                    .sort((a, b) => {
                        var textA = a.professor.fiscal_code.toUpperCase();
                        var textB = b.professor.fiscal_code.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                dispatch(deleteAllProfessorAbsencesSuccess(absences));
            })
            .catch(error => {
                let errorMessage = 'Qualcosa è andato storto. Per favore riprovare.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined && error.response.data.error !== undefined) {
                    errorMessage = error.response.data.error;
                }
                dispatch(deleteAllProfessorAbsencesFail(errorMessage));
            })
    };
};

export const getResolutions = (chosenDate) => {
    return dispatch => {
        dispatch(getResolutionsStart());
        axios.get('/v1/professor/resolution?date=' + chosenDate)
            .then(response => {
                dispatch(getResolutionsSuccess(response.data.resolutions, response.data.notifications));
            })
            .catch(error => {
                console.log('[Middleware: getResolutions] error', error);
                dispatch(getResolutionsFail());
            });
    }
};