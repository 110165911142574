import {
    GET_ADMINS_START,
    GET_ADMINS_SUCCESS,
    GET_ADMINS_FAIL,
    REGISTER_ADMIN_START,
    REGISTER_ADMIN_SUCCESS,
    REGISTER_ADMIN_FAIL,
    REMOVE_ADMIN_START,
    REMOVE_ADMIN_SUCCESS,
    REMOVE_ADMIN_FAIL,
    SHOW_ADMIN_INFO_MODAL,
} from '../actions';

import axios from '../../axios-professors';

export const getAdminsStart = () => {
    return {
        type: GET_ADMINS_START
    }
};

export const getAdminsSuccess = (admins) => {
    return {
        type: GET_ADMINS_SUCCESS,
        admins: admins,
    }
};

export const getAdminsFail = () => {
    return {
        type: GET_ADMINS_FAIL
    }
};

export const registerAdminStart = () => {
    return {
        type: REGISTER_ADMIN_START
    }
};

export const registerAdminSuccess = (admins) => {
    return {
        type: REGISTER_ADMIN_SUCCESS,
        admins: admins,
    }
};

export const registerAdminFail = (errorMessage) => {
    return {
        type: REGISTER_ADMIN_FAIL,
        errorMessage: errorMessage,
    }
};

export const removeAdminStart = () => {
    return {
        type: REMOVE_ADMIN_START
    }
};

export const removeAdminSuccess = (admins) => {
    return {
        type: REMOVE_ADMIN_SUCCESS,
        admins: admins,
    }
};

export const removeAdminFail = (errorMessage) => {
    return {
        type: REMOVE_ADMIN_FAIL,
        errorMessage: errorMessage,
    }
};

/////////////////////////////////////////////////////////////////////////////
// OTHER
/////////////////////////////////////////////////////////////////////////////
export const setShowAdminInfoModal = (show) => {
    return {
        type: SHOW_ADMIN_INFO_MODAL,
        show: show
    }
};


////// MIDDLEWARES (or Async Functions)
export const getAdmins = () => {
    return dispatch => {
        dispatch(getAdminsStart());
        axios.get('/v1/admin/all')
            .then(response => {
                const admins = response.data.admins
                    .sort((a, b) => {
                        var textA = a.username.toUpperCase();
                        var textB = b.username.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                dispatch(getAdminsSuccess(admins));
            })
            .catch(error => {
                console.log('[getAdmins] ERROR ', error);
                dispatch(getAdminsFail());
            })
    }
};

export const registerAdmin = (data) => {
    return dispatch => {
        dispatch(registerAdminStart());
        axios.post('/v1/admin', data)
            .then(response => {
                const admins = response.data.admins
                    .sort((a, b) => {
                        var textA = a.username.toUpperCase();
                        var textB = b.username.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                dispatch(registerAdminSuccess(admins));
            })
            .catch(error => {
                console.log('[registerAdmin] ERROR ', error);
                let errorMessage = 'Qualcosa è andato storto. Per favore riprovare.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined && error.response.data.error !== undefined) {
                    errorMessage = error.response.data.error;
                }
                dispatch(registerAdminFail(errorMessage));
            })
    }
};

export const removeAdmin = (data) => {
    return dispatch => {
        dispatch(removeAdminStart());
        axios.delete('/v1/admin', { data: data })
            .then(response => {
                const admins = response.data.admins
                    .sort((a, b) => {
                        var textA = a.username.toUpperCase();
                        var textB = b.username.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                dispatch(removeAdminSuccess(admins));
            })
            .catch(error => {
                console.log('[removeAdmin] ERROR ', error);
                let errorMessage = 'Qualcosa è andato storto. Per favore riprovare.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined && error.response.data.error !== undefined) {
                    errorMessage = error.response.data.error;
                }
                dispatch(removeAdminFail(errorMessage));
            })
    }
};