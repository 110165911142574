import {
    GET_SCHOOL_REQUESTS_START,
    GET_SCHOOL_REQUESTS_SUCCESS,
    GET_SCHOOL_REQUESTS_FAIL,
    GET_SCHOOL_ARCHIVE_START,
    GET_SCHOOL_ARCHIVE_SUCCESS,
    GET_SCHOOL_ARCHIVE_FAIL,
    MAKE_DECISION_ON_SCHOOL_REQUEST_START,
    MAKE_DECISION_ON_SCHOOL_REQUEST_SUCCESS,
    MAKE_DECISION_ON_SCHOOL_REQUEST_FAIL,
    SHOW_SCHOOL_REQUEST_INFO_MODAL,
} from '../actions';

import axios from '../../axios-professors';

export const getSchoolRequestsStart = () => {
    return {
        type: GET_SCHOOL_REQUESTS_START
    }
};

export const getSchoolRequestsSuccess = (schoolRequests) => {
    return {
        type: GET_SCHOOL_REQUESTS_SUCCESS,
        schoolRequests: schoolRequests,
    }
};

export const getSchoolRequestsFail = () => {
    return {
        type: GET_SCHOOL_REQUESTS_FAIL
    }
};

export const getSchoolArchiveStart = () => {
    return {
        type: GET_SCHOOL_ARCHIVE_START
    }
};

export const getSchoolArchiveSuccess = (schoolArchive) => {
    return {
        type: GET_SCHOOL_ARCHIVE_SUCCESS,
        schoolArchive: schoolArchive,
    }
};

export const getSchoolArchiveFail = () => {
    return {
        type: GET_SCHOOL_ARCHIVE_FAIL
    }
};

export const makeDecisionOnSchoolRequestStart = () => {
    return {
        type: MAKE_DECISION_ON_SCHOOL_REQUEST_START
    }
};

export const makeDecisionOnSchoolRequestSuccess = (schoolRequests) => {
    return {
        type: MAKE_DECISION_ON_SCHOOL_REQUEST_SUCCESS,
        schoolRequests: schoolRequests,
    }
};

export const makeDecisionOnSchoolRequestFail = (errorMessage) => {
    return {
        type: MAKE_DECISION_ON_SCHOOL_REQUEST_FAIL,
        errorMessage: errorMessage,
    }
};

/////////////////////////////////////////////////////////////////////////////
// OTHER
/////////////////////////////////////////////////////////////////////////////
export const setShowSchoolRequestInfoModal = (show) => {
    return {
        type: SHOW_SCHOOL_REQUEST_INFO_MODAL,
        show: show
    }
};


////// MIDDLEWARES (or Async Functions)
export const getSchoolRequests = () => {
    return dispatch => {
        dispatch(getSchoolRequestsStart());
        axios.get('/v1/manage/school_request/all')
            .then(response => {
                const schoolRequests = response.data.school_requests
                    .sort((a, b) => {
                        var creationTimeA = a.created_at;
                        var creationTimeB = b.created_at;
                        return (creationTimeA < creationTimeB) ? 1 : (creationTimeA > creationTimeB) ? -1 : 0;
                    });
                dispatch(getSchoolRequestsSuccess(schoolRequests));
            })
            .catch(error => {
                console.log('[getSchoolRequests] ERROR ', error);
                let errorMessage = 'Qualcosa è andato storto. Per favore riprovare.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined && error.response.data.error !== undefined) {
                    errorMessage = error.response.data.error;
                }
                dispatch(getSchoolRequestsFail(errorMessage));
            })
    }
};

export const makeDecisionOnSchoolRequest = (data) => {
    return dispatch => {
        dispatch(makeDecisionOnSchoolRequestStart());
        axios.post('/v1/manage/school_request', data)
            .then(response => {
                const schoolRequests = response.data.school_requests
                    .sort((a, b) => {
                        var creationTimeA = a.created_at;
                        var creationTimeB = b.created_at;
                        return (creationTimeA < creationTimeB) ? 1 : (creationTimeA > creationTimeB) ? -1 : 0;
                    });
                dispatch(makeDecisionOnSchoolRequestSuccess(schoolRequests));
            })
            .catch(error => {
                console.log('[makeDecisionOnSchoolRequest] ERROR ', error);
                let errorMessage = 'Qualcosa è andato storto. Per favore riprovare.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined && error.response.data.error !== undefined) {
                    errorMessage = error.response.data.error;
                }
                dispatch(makeDecisionOnSchoolRequestFail(errorMessage));
            })
    }
};

export const getSchoolArchive = () => {
    return dispatch => {
        dispatch(getSchoolArchiveStart());
        axios.get('/v1/manage/archive')
            .then(response => {
                const schoolArchive = response.data.school_requests
                    .filter(req => req.status !== "pending")
                    .sort((a, b) => {
                        var creationTimeA = a.created_at;
                        var creationTimeB = b.created_at;
                        return (creationTimeA < creationTimeB) ? 1 : (creationTimeA > creationTimeB) ? -1 : 0;
                    });
                dispatch(getSchoolArchiveSuccess(schoolArchive));
            })
            .catch(error => {
                console.log('[getSchoolArchive] ERROR ', error);
                let errorMessage = 'Qualcosa è andato storto. Per favore riprovare.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined && error.response.data.error !== undefined) {
                    errorMessage = error.response.data.error;
                }
                dispatch(getSchoolArchiveFail(errorMessage));
            })
    }
};