import {
    LOAD_SCHEDULE_START,
    LOAD_SCHEDULE_SUCCESS,
    LOAD_SCHEDULE_FAIL,
    CONFIRM_NEW_SCHEDULE_START,
    CONFIRM_NEW_SCHEDULE_SUCCESS,
    CONFIRM_NEW_SCHEDULE_FAIL,
    RESET_SCHOOL_START,
    RESET_SCHOOL_SUCCESS,
    RESET_SCHOOL_FAIL,
    SHOW_LOAD_SCHEDULE_MODAL,
    SHOW_RESET_SCHOOL_MODAL,
} from '../actions';

import axios from '../../axios-professors';

export const loadScheduleStart = () => {
    return {
        type: LOAD_SCHEDULE_START
    }
};

export const loadScheduleSuccess = (data, scheduleType) => {
    return {
        type: LOAD_SCHEDULE_SUCCESS,
        data: data,
        scheduleType: scheduleType,
    }
};

export const loadScheduleFail = (errorMessage) => {
    return {
        type: LOAD_SCHEDULE_FAIL,
        errorMessage: errorMessage
    }
};

export const confirmNewScheduleStart = () => {
    return {
        type: CONFIRM_NEW_SCHEDULE_START
    }
};

export const confirmNewScheduleSuccess = () => {
    return {
        type: CONFIRM_NEW_SCHEDULE_SUCCESS
    }
};

export const confirmNewScheduleFail = (errorMessage) => {
    return {
        type: CONFIRM_NEW_SCHEDULE_FAIL,
        errorMessage: errorMessage,
    }
};

export const resetSchoolStart = () => {
    return {
        type: RESET_SCHOOL_START
    }
};

export const resetSchoolSuccess = (message) => {
    return {
        type: RESET_SCHOOL_SUCCESS,
        message: message,
    }
};

export const resetSchoolFail = (errorMessage) => {
    return {
        type: RESET_SCHOOL_FAIL,
        errorMessage: errorMessage,
    }
};

export const showLoadScheduleModal = (show, scheduleType) => {
    return {
        type: SHOW_LOAD_SCHEDULE_MODAL,
        show: show,
        scheduleType: scheduleType,
    }
};

export const showResetSchoolModal = (show, scheduleType) => {
    return {
        type: SHOW_RESET_SCHOOL_MODAL,
        show: show,
        scheduleType: scheduleType,
    }
};

////// MIDDLEWARES (or Async Functions)
export const loadSchedule = (data, scheduleType) => {
    const url = scheduleType === 3 ? '/v1/school_class/schedule' : '/v1/schedule';

    return dispatch => {
        dispatch(loadScheduleStart());
        axios.post(url, data)
            .then(response => {
                // TODO: sort this data
                dispatch(loadScheduleSuccess(response.data, scheduleType));
                // TODO: re-enable dispatch(actions.getTodos());
            })
            .catch(error => {
                let errorMessage = 'Qualcosa è andato storto. Per favore riprovare.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined && error.response.data.error !== undefined) {
                    errorMessage = error.response.data.error;
                }
                dispatch(loadScheduleFail(errorMessage));
            })
    }
};

export const confirmNewSchedule = (data, scheduleType) => {
    const url = scheduleType === 3 ? '/v1/school_class/schedule/challenge' : '/v1/schedule/challenge';

    return dispatch => {
        dispatch(confirmNewScheduleStart());
        axios.post(url, data)
            .then(response => {
                dispatch(confirmNewScheduleSuccess());
                // TODO: re-enable dispatch(actions.getTodos());
            })
            .catch(error => {
                let errorMessage = 'Qualcosa è andato storto. Per favore riprovare.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined && error.response.data.error !== undefined) {
                    errorMessage = error.response.data.error;
                }
                dispatch(confirmNewScheduleFail(errorMessage));
            })
    }
};

export const resetSchool = (data) => {
    return dispatch => {
        dispatch(resetSchoolStart());
        axios.post('/v1/school/reset', data)
            .then(response => {
                dispatch(resetSchoolSuccess("Scuola resettata con successo."));
            })
            .catch(error => {
                console.log('[resetSchool] ERROR ', error);
                dispatch(resetSchoolFail());
            })
    }
};