import {
    GET_SCHOOL_REQUESTS_START,
    GET_SCHOOL_REQUESTS_SUCCESS,
    GET_SCHOOL_REQUESTS_FAIL,
    GET_SCHOOL_ARCHIVE_START,
    GET_SCHOOL_ARCHIVE_SUCCESS,
    GET_SCHOOL_ARCHIVE_FAIL,
    MAKE_DECISION_ON_SCHOOL_REQUEST_START,
    MAKE_DECISION_ON_SCHOOL_REQUEST_SUCCESS,
    MAKE_DECISION_ON_SCHOOL_REQUEST_FAIL,
    SHOW_SCHOOL_REQUEST_INFO_MODAL,
} from '../actions';


import { updateObject } from '../utility';

const initialState = {
    schoolRequests: [],
    schoolArchive: [],

    showSchoolRequestInfoModal: false,
    isLoadingOnRegistering: false,

    loading: false,
    message: null,
    messageType: null,
}

const getSchoolRequestsStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        message: null,
        messageType: null,
    });
};

const getSchoolRequestsSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        schoolRequests: action.schoolRequests,
    });
};

const getSchoolRequestsFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        message: action.error,
        messageType: 'error',
    });
};

const getSchoolArchiveStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        message: null,
        messageType: null,
    });
};

const getSchoolArchiveSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        schoolArchive: action.schoolArchive,
    });
};

const getSchoolArchiveFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        message: action.error,
        messageType: 'error',
    });
};

const makeDecisionOnSchoolRequestStart = (state, action) => {
    return updateObject(state, {
        isLoadingOnRegistering: true,
        message: null,
        messageType: null,
    });
};

const makeDecisionOnSchoolRequestSuccess = (state, action) => {
    return updateObject(state, {
        isLoadingOnRegistering: false,
        schoolRequests: [...action.schoolRequests],
        message: 'Scuola attivata con successo!',
        messageType: 'success',
        showSchoolRequestInfoModal: false,
    });
};

const makeDecisionOnSchoolRequestFail = (state, action) => {
    return updateObject(state, {
        isLoadingOnRegistering: false,

        message: action.errorMessage,
        messageType: 'error',
    });
};

/////////////////////////////////////////////////////////////////////////////
// OTHER
/////////////////////////////////////////////////////////////////////////////
const setShowSchoolRequestInfoModal = (state, action) => {
    return updateObject(state, {
        showSchoolRequestInfoModal: action.show,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SCHOOL_REQUESTS_START: return getSchoolRequestsStart(state, action);
        case GET_SCHOOL_REQUESTS_SUCCESS: return getSchoolRequestsSuccess(state, action);
        case GET_SCHOOL_REQUESTS_FAIL: return getSchoolRequestsFail(state, action);
        case GET_SCHOOL_ARCHIVE_START: return getSchoolArchiveStart(state, action);
        case GET_SCHOOL_ARCHIVE_SUCCESS: return getSchoolArchiveSuccess(state, action);
        case GET_SCHOOL_ARCHIVE_FAIL: return getSchoolArchiveFail(state, action);
        case MAKE_DECISION_ON_SCHOOL_REQUEST_START: return makeDecisionOnSchoolRequestStart(state, action);
        case MAKE_DECISION_ON_SCHOOL_REQUEST_SUCCESS: return makeDecisionOnSchoolRequestSuccess(state, action);
        case MAKE_DECISION_ON_SCHOOL_REQUEST_FAIL: return makeDecisionOnSchoolRequestFail(state, action);
        case SHOW_SCHOOL_REQUEST_INFO_MODAL: return setShowSchoolRequestInfoModal(state, action);
        default: return state;
    }
}

export default reducer;