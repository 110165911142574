import {
    GET_PROFESSOR_TO_SUBJECTS_START,
    GET_PROFESSOR_TO_SUBJECTS_SUCCESS,
    GET_PROFESSOR_TO_SUBJECTS_FAIL,
    REGISTER_PROFESSOR_TO_SUBJECTS_START,
    REGISTER_PROFESSOR_TO_SUBJECTS_SUCCESS,
    REGISTER_PROFESSOR_TO_SUBJECTS_FAIL,
    SHOW_PROFESSOR_INFO_MODAL,
} from '../actions';

import axios from '../../axios-professors';

export const getProfessorToSubjectsStart = () => {
    return {
        type: GET_PROFESSOR_TO_SUBJECTS_START
    }
};

export const getProfessorToSubjectsSuccess = (professorToSubjects, subjects) => {
    return {
        type: GET_PROFESSOR_TO_SUBJECTS_SUCCESS,
        professorToSubjects: professorToSubjects,
        subjects: subjects,
    }
};

export const getProfessorToSubjectsFail = () => {
    return {
        type: GET_PROFESSOR_TO_SUBJECTS_FAIL
    }
};

export const registerProfessorToSubjectsStart = () => {
    return {
        type: REGISTER_PROFESSOR_TO_SUBJECTS_START
    }
};

export const registerProfessorToSubjectsSuccess = (professorToSubjects, subjects) => {
    return {
        type: REGISTER_PROFESSOR_TO_SUBJECTS_SUCCESS,
        professorToSubjects: professorToSubjects,
        subjects: subjects,
    }
};

export const registerProfessorToSubjectsFail = (errorMessage) => {
    return {
        type: REGISTER_PROFESSOR_TO_SUBJECTS_FAIL,
        errorMessage: errorMessage,
    }
};

/////////////////////////////////////////////////////////////////////////////
// OTHER
/////////////////////////////////////////////////////////////////////////////
export const setShowProfessorInfoModal = (show) => {
    return {
        type: SHOW_PROFESSOR_INFO_MODAL,
        show: show
    }
};


////// MIDDLEWARES (or Async Functions)
export const getProfessorToSubjects = () => {
    return dispatch => {
        dispatch(getProfessorToSubjectsStart());
        axios.get('/v1/professor/subject')
            .then(response => {
                const professorToSubjects = response.data.professor_to_subject
                    .sort((a, b) => {
                        var textA = a.professor.fiscal_code.toUpperCase();
                        var textB = b.professor.fiscal_code.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                const subjects = response.data.subjects
                    .sort((a, b) => {
                        var textA = a.name.toUpperCase();
                        var textB = b.name.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                dispatch(getProfessorToSubjectsSuccess(professorToSubjects, subjects));
            })
            .catch(error => {
                console.log('[getProfessorToSubjects] ERROR ', error);
                dispatch(getProfessorToSubjectsFail());
            })
    }
};

export const registerProfessorToSubjects = (data) => {
    return dispatch => {
        dispatch(registerProfessorToSubjectsStart());
        axios.post('/v1/professor/subject', data)
            .then(response => {
                const professorToSubjects = response.data.professor_to_subject
                    .sort((a, b) => {
                        var textA = a.professor.fiscal_code.toUpperCase();
                        var textB = b.professor.fiscal_code.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                const subjects = response.data.subjects
                    .sort((a, b) => {
                        var textA = a.name.toUpperCase();
                        var textB = b.name.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                dispatch(registerProfessorToSubjectsSuccess(professorToSubjects, subjects));
            })
            .catch(error => {
                console.log('[registerProfessorToSubjects] ERROR ', error);
                let errorMessage = 'Qualcosa è andato storto. Per favore riprovare.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined && error.response.data.error !== undefined) {
                    errorMessage = error.response.data.error;
                }
                dispatch(registerProfessorToSubjectsFail(errorMessage)); 
            })
    }
};