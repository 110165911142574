import {
    GET_PROFESSORS_AVAILABLE_START,
    GET_PROFESSORS_AVAILABLE_SUCCESS,
    GET_PROFESSORS_AVAILABLE_FAIL,
} from '../actions';

import axios from '../../axios-professors';

export const getProfessorsAvailableStart = () => {
    return {
        type: GET_PROFESSORS_AVAILABLE_START
    };
};

export const getProfessorsAvailableSuccess = (professors) => {
    return {
        type: GET_PROFESSORS_AVAILABLE_SUCCESS,
        professors: professors,
    };
};

export const getProfessorsAvailableFail = (errorMessage) => {
    return {
        type: GET_PROFESSORS_AVAILABLE_FAIL,
        errorMessage: errorMessage,
    };
};


////// MIDDLEWARES (or Async Functions)
export const initAvailableProfessors = (chosenDate) => {
    return dispatch => {
        dispatch(getProfessorsAvailableStart());
        axios.get('/v1/professor/availability?date=' + chosenDate)
            .then(response => {
                const availability = response.data.reduce(function (myList, obj) {
                    const dnrList = obj.availability.dnr_hours.map(hour => {
                        var rObj = {};
                        rObj['professor'] = obj.professor;
                        rObj['availability'] = {
                            'day_hour': hour,
                            'type': 'disposizione',
                        };
                        rObj['resolution'] = obj.resolutions.filter(res => res.day_hour === hour);
                        return rObj;
                    });
                    const permList = obj.availability.perm_tickets_hours.map(hour => {
                        var rObj = {};
                        rObj['professor'] = obj.professor;
                        rObj['availability'] = {
                            'day_hour': hour,
                            'type': 'recupero ora',
                        };
                        rObj['resolution'] = obj.resolutions.filter(res => res.day_hour === hour);
                        return rObj;
                    });
                    const classOutList = obj.availability.class_out_hours.map(hour => {
                        var rObj = {};
                        rObj['professor'] = obj.professor;
                        rObj['availability'] = {
                            'day_hour': hour,
                            'type': 'classe fuori',
                        };
                        rObj['resolution'] = obj.resolutions.filter(res => res.day_hour === hour);
                        return rObj;
                    });
                    const paidList = obj.availability.paid_hours.map(hour => {
                        var rObj = {};
                        rObj['professor'] = obj.professor;
                        rObj['availability'] = {
                            'day_hour': hour,
                            'type': 'retribuita',
                        };
                        rObj['resolution'] = obj.resolutions.filter(res => res.day_hour === hour);
                        return rObj;
                    });
                    return [...myList, ...dnrList, ...permList, ...classOutList, ...paidList];
                }, []);
                const professors = availability.sort((a, b) => {
                    var availabilityA = a.availability.day_hour;
                    var availabilityB = b.availability.day_hour;
                    if (availabilityA < availabilityB) return -1;
                    if (availabilityA > availabilityB) return 1;
                    var textA = a.professor.fiscal_code.toUpperCase();
                    var textB = b.professor.fiscal_code.toUpperCase();
                    return textA - textB;
                })
                dispatch(getProfessorsAvailableSuccess(professors));
            })
            .catch(error => {
                console.log('[Middleware: initAvailableProfessors] ERROR availableProfessors', error);
                dispatch(getProfessorsAvailableFail(''));
            });
    };
};