import {
    GET_ANY_HOUR_PROFESSORS_AVAILABLE_START,
    GET_ANY_HOUR_PROFESSORS_AVAILABLE_SUCCESS,
    GET_ANY_HOUR_PROFESSORS_AVAILABLE_FAIL,
    GET_PROFESSOR_FREE_HOURS_START,
    GET_PROFESSOR_FREE_HOURS_SUCCESS,
    GET_PROFESSOR_FREE_HOURS_FAIL,
    SAVE_ABSENT_PROFESSOR_AND_PERM_START,
    SAVE_ABSENT_PROFESSOR_AND_PERM_SUCCESS,
    SAVE_ABSENT_PROFESSOR_AND_PERM_FAIL,
    SET_PROFESSOR_SCHEDULE,
    SET_SHOW_PERM_PROFESSOR_MODAL,
} from '../actions';

import axios from '../../axios-professors';

import moment from 'moment';
import 'moment/locale/it';

/////////////////////////////////////////////////////////////////////////////
// PROFESSOR AVAILABILITY
/////////////////////////////////////////////////////////////////////////////
export const getAnyHourProfessorsAvailableStart = () => {
    return {
        type: GET_ANY_HOUR_PROFESSORS_AVAILABLE_START
    }
};

export const getAnyHourProfessorsAvailableSuccess = (availability, schoolClassSettings) => {
    return {
        type: GET_ANY_HOUR_PROFESSORS_AVAILABLE_SUCCESS,
        availability: availability,
        schoolClassSettings: schoolClassSettings,
    }
};

export const getAnyHourProfessorsAvailableFail = (errorMessage) => {
    return {
        type: GET_ANY_HOUR_PROFESSORS_AVAILABLE_FAIL,
        errorMessage: errorMessage
    }
};

/////////////////////////////////////////////////////////////////////////////
// PROFESSOR SCHEDULE
/////////////////////////////////////////////////////////////////////////////
export const setProfessorSchedule = (schedule) => {
    return {
        type: SET_PROFESSOR_SCHEDULE,
        schedule: schedule
    }
};

/////////////////////////////////////////////////////////////////////////////
// PROFESSOR FREE HOURS
/////////////////////////////////////////////////////////////////////////////
export const getProfessorFreeHoursStart = () => {
    return {
        type: GET_PROFESSOR_FREE_HOURS_START
    }
};

export const getProfessorFreeHoursSuccess = (availability, chosenDate) => {
    return {
        type: GET_PROFESSOR_FREE_HOURS_SUCCESS,
        availability: availability,
        chosenDate: chosenDate,
    }
};

export const getProfessorFreeHoursFail = (errorMessage) => {
    return {
        type: GET_PROFESSOR_FREE_HOURS_FAIL,
        errorMessage: errorMessage,
    }
};

/////////////////////////////////////////////////////////////////////////////
// PERM
/////////////////////////////////////////////////////////////////////////////
export const saveAbsentProfessorAndPermStart = () => {
    return {
        type: SAVE_ABSENT_PROFESSOR_AND_PERM_START,
    }
};

export const saveAbsentProfessorAndPermSuccess = () => {
    return {
        type: SAVE_ABSENT_PROFESSOR_AND_PERM_SUCCESS
    }
};

export const saveAbsentProfessorAndPermFail = (errorMessage) => {
    return {
        type: SAVE_ABSENT_PROFESSOR_AND_PERM_FAIL,
        errorMessage: errorMessage
    }
};

/////////////////////////////////////////////////////////////////////////////
// OTHERS
/////////////////////////////////////////////////////////////////////////////
export const setShowPermProfessorModal = (show) => {
    return {
        type: SET_SHOW_PERM_PROFESSOR_MODAL,
        show: show
    }
};

////// MIDDLEWARES (or Async Functions)
export const initHourlyAvailableProfessors = (chosenDate) => {
    return dispatch => {
        dispatch(getAnyHourProfessorsAvailableStart());
        axios.get('/v1/professor/availability?date=' + chosenDate)
            .then(response => {
                const availability = response.data
                    .sort((a, b) => {
                        var textA = a.professor.fiscal_code.toUpperCase();
                        var textB = b.professor.fiscal_code.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                axios.get('/v1/school/settings')
                    .then(response => {
                        const schoolClassSettings = response.data;
                        dispatch(getAnyHourProfessorsAvailableSuccess(availability, schoolClassSettings));
                    })
                    .catch(error => {
                        console.log('[getNotAbsentProfessors] Middleware Error', error);
                    })
            })
            .catch(error => {
                console.log('[Middleware: initHourlyAvailableProfessors] error', error);
                dispatch(getAnyHourProfessorsAvailableFail(''));
            });
    };
};

export const initProfessorSchedule = (chosenDate, professorId) => {
    return dispatch => {
        // dispatch(setProfessorScheduleStart());
        axios.get('/v1/professor/day_hour/class?date=' + chosenDate + '&professor_id=' + professorId)
            .then(response => {
                const schedule = response.data
                    .sort((a, b) => {
                        return a.day_hour - b.day_hour;
                    })
                dispatch(setProfessorSchedule(schedule));
            })
            .catch(error => {
                console.log('[Middleware: initProfessorSchedule] error', error);
                // dispatch(setProfessorScheduleFail());
            });
    };
};

export const initProfessorAvailability = (chosenDate, professorId) => {
    // TODO: this can be improved. no need to fetch all professors availability
    return dispatch => {
        dispatch(getProfessorFreeHoursStart());
        axios.get('/v1/professor/availability?date=' + chosenDate)
            .then(response => {
                const availability = response.data.filter(avail => avail.professor.id === professorId);
                dispatch(getProfessorFreeHoursSuccess(availability, moment(chosenDate, 'YYYY-MM-DD').toDate()));
            })
            .catch(error => {
                console.log('[Middleware: initProfessorAvailability] error', error);
                dispatch(getProfessorFreeHoursFail(''));
            });
    };
};

export const saveAbsentProfessorAndPerm = (data) => {
    return dispatch => {
        dispatch(saveAbsentProfessorAndPermStart());
        axios.post('/v1/professor/absence', data)
            .then(response => {
                dispatch(saveAbsentProfessorAndPermSuccess());
            })
            .catch(error => {
                let errorMessage = 'Qualcosa è andato storto. Per favore riprovare.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined && error.response.data.error !== undefined) {
                    errorMessage = error.response.data.error;
                }
                dispatch(saveAbsentProfessorAndPermFail(errorMessage));
            })
    };
};
