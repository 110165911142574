import {
    AUTH_START,
    AUTH_SUCCESS,
    AUTH_FAIL,
    AUTH_LOGOUT_START,
    AUTH_LOGOUT_SUCCESS,
    AUTH_LOGOUT_FAIL,
    AUTH_LOGOUT,
    UPDATE_TODOS,
    REFRESH_JWT_TOKEN_START,
    REFRESH_JWT_TOKEN_END,
    SIGNUP_START,
    SIGNUP_SUCCESS,
    SIGNUP_FAIL,
    RESET_PASSWORD_START,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL,
    FORGOT_PASSWORD_START,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAIL,
    ADMIN_SIGNUP_START,
    ADMIN_SIGNUP_SUCCESS,
    ADMIN_SIGNUP_FAIL,    
    ASK_NEW_LINK_START,
    ASK_NEW_LINK_SUCCESS,
    ASK_NEW_LINK_FAIL,
} from '../actions';

import axios from '../../axios-professors';


export const authStart = () => {
    return {
        type: AUTH_START
    };
};

export const authSuccess = (token, todos) => {
    return {
        type: AUTH_SUCCESS,
        token: token,
        todos: todos,
    }
}

export const authFail = (errorMessage) => {
    return {
        type: AUTH_FAIL,
        errorMessage: errorMessage
    }
}

export const authLogoutStart = () => {
    return {
        type: AUTH_LOGOUT_START
    };
};

export const authLogoutSuccess = (token, todos) => {
    return {
        type: AUTH_LOGOUT_SUCCESS,
        token: token,
        todos: todos,
    }
}

export const authLogoutFail = (errorMessage) => {
    return {
        type: AUTH_LOGOUT_FAIL,
        errorMessage: errorMessage
    }
}

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationTime');
    localStorage.removeItem('schoolID');
    localStorage.removeItem('schoolName');
    localStorage.removeItem('username');
    localStorage.removeItem('manager');

    // TODO: remove this!
    localStorage.removeItem('modalWelcomeShown0');
    localStorage.removeItem('modalWelcomeShown1');
    localStorage.removeItem('modalWelcomeShown2');
    localStorage.removeItem('modalWelcomeShown3');
    localStorage.removeItem('modalWelcomeShown4');
    localStorage.removeItem('modalWelcomeShown5');
    localStorage.removeItem('modalWelcomeShown6');
    localStorage.removeItem('modalWelcomeShown7');

    return {
        type: AUTH_LOGOUT
    }
}

export const updateTodos = (todos) => {
    return {
        type: UPDATE_TODOS,
        todos: todos,
    }
}

export const refreshJWTTokenStart = () => {
    return {
        type: REFRESH_JWT_TOKEN_START
    }
}

export const refreshJWTTokenEnd = () => {
    return {
        type: REFRESH_JWT_TOKEN_END
    }
}

export const signupStart = () => {
    return {
        type: SIGNUP_START
    };
};

export const signupSuccess = () => {
    return {
        type: SIGNUP_SUCCESS,
    }
}

export const signupFail = (errorMessage) => {
    return {
        type: SIGNUP_FAIL,
        errorMessage: errorMessage
    }
}

export const resetPasswordStart = () => {
    return {
        type: RESET_PASSWORD_START
    }
}

export const resetPasswordSuccess = () => {
    return {
        type: RESET_PASSWORD_SUCCESS
    }
}

export const resetPasswordFail = (errorMessage) => {
    return {
        type: RESET_PASSWORD_FAIL,
        errorMessage: errorMessage
    };
};

export const forgotPasswordStart = () => {
    return {
        type: FORGOT_PASSWORD_START
    }
}

export const forgotPasswordSuccess = () => {
    return {
        type: FORGOT_PASSWORD_SUCCESS
    }
}

export const forgotPasswordFail = (errorMessage) => {
    return {
        type: FORGOT_PASSWORD_FAIL,
        errorMessage: errorMessage
    };
};


export const adminSignupStart = () => {
    return {
        type: ADMIN_SIGNUP_START
    }
}

export const adminSignupSuccess = () => {
    return {
        type: ADMIN_SIGNUP_SUCCESS
    }
}

export const adminSignupFail = (errorMessage) => {
    return {
        type: ADMIN_SIGNUP_FAIL,
        errorMessage: errorMessage
    };
};

export const askNewLinkStart = () => {
    return {
        type: ASK_NEW_LINK_START
    }
}

export const askNewLinkSuccess = () => {
    return {
        type: ASK_NEW_LINK_SUCCESS
    }
}

export const askNewLinkFail = (errorMessage) => {
    return {
        type: ASK_NEW_LINK_FAIL,
        errorMessage: errorMessage
    };
};

export const refreshToken = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(refreshJWTToken());
        }, expirationTime * 1000);
    };
};

////// MIDDLEWARES (or Async Functions)
function handleResponse(response) {
    const expirationTime = new Date(new Date().getTime() + response.data.expires_in * 1000);

    localStorage.setItem('token', response.data.token);
    localStorage.setItem('expirationTime', expirationTime);
    localStorage.setItem('schoolID', response.data.school.id);
    localStorage.setItem('schoolName', response.data.school.school_name);
    localStorage.setItem('username', response.data.username);

    if(response.data.manager !== undefined && response.data.manager) {
        localStorage.setItem('manager', expirationTime);
    } else {
        localStorage.removeItem('manager');
    }
}

export const auth = (username, password, history) => {
    return dispatch => {
        dispatch(authStart());
        const data = {
            username: username,
            password: password,
        }
        axios.post('/v1/login', data)
            .then(response => {
                if (!response.data.is_admin) {
                    dispatch(authFail("Operazione non permessa!"));
                } else {
                    handleResponse(response);
                    dispatch(authSuccess(
                        response.data.token,
                        response.data.todos,
                    ));
                    dispatch(refreshToken(response.data.expires_in));
                    history.push('/');
                }
            })
            .catch(error => {
                let errorMessage = 'Qualcosa è andato storto. Per favore riprovare.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined && error.response.data.error !== undefined) {
                    errorMessage = error.response.data.error;
                }
                dispatch(authFail(errorMessage));
            })
    };
}

export const authLogout = (history) => {
    return dispatch => {
        dispatch(authLogoutStart());
        axios.post('/v1/logout')
            .then(response => {
                localStorage.removeItem('token');
                localStorage.removeItem('expirationTime');
                dispatch(authLogoutSuccess());
                history.push('/');
            })
            .catch(error => {
                let errorMessage = 'Qualcosa è andato storto. Per favore riprovare.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined && error.response.data.error !== undefined) {
                    errorMessage = error.response.data.error;
                }
                dispatch(authLogoutFail(errorMessage));
            })
    };
}

// TODO: retry if it's not 403.
// if (error.response.status === 403) {
export const refreshJWTToken = (onStart, history) => {
    return dispatch => {
        if (onStart) {
            dispatch(refreshJWTTokenStart());
        }
        axios.post('/v1/jwt/refresh')
            .then(response => {
                if (onStart) {
                    dispatch(refreshJWTTokenEnd());
                }

                handleResponse(response);
                dispatch(authSuccess(
                    response.data.token,
                    response.data.todos,
                ));
                dispatch(refreshToken(response.data.expires_in));
                if (history) {
                    history.push('/');
                }
            })
            .catch(error => {
                if (onStart) {
                    dispatch(refreshJWTTokenEnd());
                }
                dispatch(logout());
            })
    };
}

export const authCheckState = (history) => {
    return dispatch => {
        dispatch(refreshJWTToken('dummyvalue', history));
    }
}

export const getTodos = () => {
    return dispatch => {
        axios.get('/v1/todos')
            .then(response => {
                dispatch(updateTodos(
                    response.data.todos,
                ));
            })
            .catch(error => {
                console.log('ERROR get todo TBD', error)
            })
    };
};

export const skipTodo = (data, callBack) => {
    return dispatch => {
        axios.post('/v1/todos', data)
            .then(response => {
                dispatch(updateTodos(
                    response.data.todos,
                ));
                dispatch(callBack(false, null));
            })
            .catch(error => {
                console.log('ERROR skip todo TBD', error)
            })
    };
};

export const signup = (data, history) => {
    return dispatch => {
        dispatch(signupStart());
        axios.post('/v1/school', data)
            .then(response => {
                dispatch(signupSuccess());
                history.push('/');
            })
            .catch(error => {
                let errorMessage = 'Qualcosa è andato storto. Per favore riprovare.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined && error.response.data.error !== undefined) {
                    errorMessage = error.response.data.error;
                }
                dispatch(signupFail(errorMessage));
            })
    };
}

export const forgotPassword = (username, history) => {
    return dispatch => {
        dispatch(forgotPasswordStart());
        const data = {
            username: username,
        }
        axios.post('/v1/password/reset/challenge', data)
            .then(response => {
                dispatch(forgotPasswordSuccess());
                history.push('/');
            })
            .catch(error => {
                let errorMessage = 'Qualcosa è andato storto. Per favore riprovare.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined && error.response.data.error !== undefined) {
                    errorMessage = error.response.data.error;
                }
                dispatch(forgotPasswordFail(errorMessage));
            })
    };
}

export const resetPassword = (data, history, isResetPassword, isMobile) => {
    return dispatch => {
        dispatch(resetPasswordStart());
        axios.post('/v1/password/reset', data)
            .then(response => {
                dispatch(resetPasswordSuccess());
                if (isMobile === 'true') {
                    history.push({
                        pathname: '/user/go-mobile',
                        state: { isResetPassword: isResetPassword }
                    });
                } else {
                    history.push('/user/login');
                }
            })
            .catch(error => {
                if (error.response && error.response.status && error.response.status === 422) {
                    history.push({
                        pathname: '/user/inactive-link',
                        state: {
                            request: data,
                            isResetPassword: isResetPassword,
                            isMobile: isMobile,
                        }
                    });
                }
                let errorMessage = 'Qualcosa è andato storto. Per favore riprovare.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined && error.response.data.error !== undefined) {
                    errorMessage = error.response.data.error;
                }
                dispatch(resetPasswordFail(errorMessage));
            })
    };
}

export const adminSignup = (data, history) => {
    return dispatch => {
        dispatch(adminSignupStart());
        axios.post('/v1/admin/signup', data)
            .then(response => {
                dispatch(adminSignupSuccess());
                history.push('/');
            })
            .catch(error => {
                let errorMessage = 'Qualcosa è andato storto. Per favore riprovare.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined && error.response.data.error !== undefined) {
                    errorMessage = error.response.data.error;
                }
                dispatch(adminSignupFail(errorMessage));
            })
    };
}

export const askNewLink = (data, history) => {
    return dispatch => {
        dispatch(askNewLinkStart());
        axios.post('/v1/password/reset/new', data)
            .then(response => {
                dispatch(askNewLinkSuccess());
            })
            .catch(error => {
                let errorMessage = 'Qualcosa è andato storto. Per favore riprovare.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined && error.response.data.error !== undefined) {
                    errorMessage = error.response.data.error;
                }
                dispatch(askNewLinkFail(errorMessage));
            })
    };
}