import {
    SUBMIT_FEEDBACK_START,
    SUBMIT_FEEDBACK_SUCCESS,
    SUBMIT_FEEDBACK_FAIL,
} from '../actions';

import axios from '../../axios-professors';

export const submitFeedbackStart = () => {
    return {
        type: SUBMIT_FEEDBACK_START
    };
};

export const submitFeedbackSuccess = () => {
    return {
        type: SUBMIT_FEEDBACK_SUCCESS
    };
};

export const submitFeedbackFail = (errorMessage) => {
    return {
        type: SUBMIT_FEEDBACK_FAIL,
        errorMessage: errorMessage,
    };
};

/// MIDDLEWARE
export const submitFeedback = (data) => {
    return dispatch => {
        dispatch(submitFeedbackStart());
        axios.post('/v1/support', data)
            .then(response => {
                dispatch(submitFeedbackSuccess());
            })
            .catch(error => {
                // console.log('[Middleware: auth] error', error.response);
                let errorMessage = 'Qualcosa è andato storto. Per favore riprovare.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined && error.response.data.error !== undefined) {
                    errorMessage = error.response.data.error;
                }
                dispatch(submitFeedbackFail(errorMessage));
            })
    };
}