import {
    GET_PROFESSORS_ANY_HOUR_AVAILABLE_START,
    GET_PROFESSORS_ANY_HOUR_AVAILABLE_SUCCESS,
    GET_PROFESSORS_ANY_HOUR_AVAILABLE_FAIL,
    SAVE_ABSENT_PROFESSORS_START,
    SAVE_ABSENT_PROFESSORS_SUCCESS,
    SAVE_ABSENT_PROFESSORS_FAIL,
    SET_SHOW_ABSENT_PROFESSOR_MODAL,
} from '../actions';

import { updateObject } from '../utility';

const initialState = {
    allDayAbsentProfessors: [],
    professorsAnyHourAvailable: [],
    schoolClassSettings: null,

    showAbsentProfessorModal: false,
    loading: false,
    loadingProfessorsAnyHourAvailable: false,

    message: null,
    messageType: null,
};

/////////////////////////////////////////////////////////////////////////////
// PROFESSORS AVAILABILITY
/////////////////////////////////////////////////////////////////////////////
const getProfessorsAnyHourAvailableStart = (state, action) => {
    return updateObject(state, {
        professorsAnyHourAvailable: [],
        loadingProfessorsAnyHourAvailable: true,
        
        message: null,
        messageType: null,
    });
};

const getProfessorsAnyHourAvailableSuccess = (state, action) => {
    const professors = action.professors
        .filter(availability => availability.presence_hours.length > 0)
        .map(availability => availability.professor);
    return updateObject(state, {
        professorsAnyHourAvailable: professors,
        loadingProfessorsAnyHourAvailable: false,
        schoolClassSettings: action.schoolClassSettings,
    });
};

const getProfessorsAnyHourAvailableFail = (state, action) => {
    return updateObject(state, {
        availaloadingProfessorsAnyHourAvailablebleClassesLoading: false,
        // message: action.errorMessage,
        // messageType: 'error',
    });
};

/////////////////////////////////////////////////////////////////////////////
// PROFESSOR ABSENCE
/////////////////////////////////////////////////////////////////////////////
const saveAbsentProfessorsStart = (state, action) => {
    return updateObject(state, {
        loading: true,

        message: null,
        messageType: null,
    });
};

const saveAbsentProfessorsSuccess = (state, action) => {
    return updateObject(state, {
        showAbsentProfessorModal: false,
        loading: false,
        message: 'Docenti Assenti salvati con successo',
        messageType: 'success'
    });
};

const saveAbsentProfessorsFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        message: action.errorMessage,
        messageType: 'error'
    });
};

/////////////////////////////////////////////////////////////////////////////
// OTHER
/////////////////////////////////////////////////////////////////////////////
const setShowAbsentProfessorModal = (state, action) => {
    if (action.show === false) {
        return updateObject(state, {
            showAbsentProfessorModal: action.show,
            professorsAnyHourAvailable: [],
        });
    }
    return updateObject(state, { showAbsentProfessorModal: action.show });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PROFESSORS_ANY_HOUR_AVAILABLE_START: return getProfessorsAnyHourAvailableStart(state, action);
        case GET_PROFESSORS_ANY_HOUR_AVAILABLE_SUCCESS: return getProfessorsAnyHourAvailableSuccess(state, action);
        case GET_PROFESSORS_ANY_HOUR_AVAILABLE_FAIL: return getProfessorsAnyHourAvailableFail(state, action);
        case SAVE_ABSENT_PROFESSORS_START: return saveAbsentProfessorsStart(state, action);
        case SAVE_ABSENT_PROFESSORS_SUCCESS: return saveAbsentProfessorsSuccess(state, action);
        case SAVE_ABSENT_PROFESSORS_FAIL: return saveAbsentProfessorsFail(state, action);
        case SET_SHOW_ABSENT_PROFESSOR_MODAL: return setShowAbsentProfessorModal(state, action);
        default: return state;
    }
};

export default reducer;